<template>
  <div class="signup1-overlay">
      <div class="container">
      <div class="box flex flex-column">
          <div>
   <div class="close-btn flex" @click="setSignup1">
       <img src="@/assets/images/close.png">
   </div>
   <div class="logo flex flex-column">
       <img src="@/assets/images/logo1.png">
       <div class="description">アカウントを作成</div>
   </div>
   <div class="social-signup-buttons">
            <div class="google btn flex">
                <img src="@/assets/images/google.png">
            <div class="text">Googleで登録</div>
            </div>
              <div class="facebook btn flex">
                  <img src="@/assets/images/fb.png">
                  <div class="text">Facebookで登録</div></div>
              <div class="line btn flex">
                  <img src="@/assets/images/line.png">
                  <div class="text">Lineで登録</div></div>
          
          <div class="email btn flex" @click="setEmailSignup">
                  <img src="@/assets/images/mail.png">
                  <div class="text">メールアドレスで登録</div></div>
          </div>
          <div class="login-link flex justify-center">
         <div> すでにアカウントをお持ちですか？ </div> <div @click="setLoginModal"><router-link :to="{name: ''}">Login</router-link> </div> 
          </div>
          </div>
          <div class="footer-text">
              * 次のステップへ進むことで、<router-link :to="{name:''}">に同意した</router-link><br>ものとみなします。
          </div>
          </div>
      </div>
       </div>
 
</template>

<script>
export default {
methods:{
    setSignup1(){
      window.onscroll = function(){}
      this.$store.commit('setSignup1Modal')
    },
    setEmailSignup(){
        this.$store.commit('setSignup1Modal')
        this.$store.commit('setEmailSignup')
    },
    setLoginModal(){
        this.$store.commit('setSignup1Modal')
        this.$store.commit('setLoginModal')
    }
}
}
</script>

<style lang="scss" scoped>
.signup1-overlay{
position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    background: rgba(0,0,0,.5);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .container{
        width: 375px;
        padding: 20px;
        .box{
            justify-content: space-between;
            position: relative;
            background: rgba(255, 255, 255, 0.95);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            width: 100%;
            height: 80vh;
            padding: 20px;
            .close-btn{
                justify-content: flex-end;
                cursor: pointer;
            }
            .logo{
                margin-top: 20px;
                align-items: center;
            }
            .social-signup-buttons{
                .btn{
                    margin-top: 15px;
                    font-size: 16px;
                   background: #FFFFFF;
                   border: 1px solid #ADADAD;
                   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                   border-radius: 50px;
                   height: 36px;
                   align-items: center;
                   padding-left: 20px;
                   cursor: pointer;
                   div{
                       padding-left: 20px;
                   }
                   img{
                       width: 21px;
                       height: 21px;
                    
                   }
                }
            }
            .login-link{
                font-size: 12px;
                margin-top: 20px;
            }
            .footer-text{
                text-align: center;
                font-size: 10px;
                color: #555555;
            } 
        }
    }
}

</style>