import { createStore } from 'vuex'

export default createStore({
  state: {
    loginModal: false,
    signup1Modal: false,
    emailSignup :false,
    feed:[
      {
        userinfo:{
          img:{
            url: require('@/assets/images/i4.png')
          }, 
          name: 'Usernickname'
        },
        postTtimeAndstate:{
           time: '3 min ago',
           state: 'Prefecture'
        },
        image:{
          url: require('@/assets/images/image3.png'),
          some:"some"
        },
          resturant:{
            name: 'Restaurant name here',
            rating: 4.5
          },
          comments:{
            comment:'If any comments are there, they come here, if blank, blank. If any comments are there...',
          }
      },
      {
        userinfo:{
          img:{
            url: require('@/assets/images/i4.png'),
          }, 
          name:'Usernickname'
        },
        postTtimeAndstate:{
          time: '3 min ago',
          state: 'Prefecture'
       },
        image:{
          url: require('@/assets/images/ir2.png')  },
          resturant:{
            name: 'Restaurant name here',
            rating: 4
          },
          comments:{
            comment:'If any comments are there, they come here, if blank, blank. If any comments are there...',
          }
      },
      {
        userinfo:{
          img:{
            url: require('@/assets/images/i4.png'),
          }, 
          name: 'Usernickname'
        },
        postTtimeAndstate:{
          time: '3 min ago',
          state: 'Prefecture'
       },
        image:{
          url: require('@/assets/images/ir3.png')  },
          resturant:{
            name: 'Restaurant name here',
            rating: 3.5
          },
          comments:{
            comment:'If any comments are there, they come here, if blank, blank. If any comments are there...',
          }
      },

    ],
    lists:[
      {
        username: 'User’s list name',
        listExplanation: 'List explanation comes here if any, if not, no display',
        totalReviews:35
      },
      {
        username: 'User’s list name',
        listExplanation: 'List explanation comes here if any, if not, no display',
        totalReviews:35
      },
      {
        username: 'User’s list name',
        listExplanation: 'List explanation comes here if any, if not, no display',
        totalReviews:35
      },
      {
        username: 'User’s list name',
        listExplanation: 'List explanation comes here if any, if not, no display',
        totalReviews:35
      },
      {
        username: 'User’s list name',
        listExplanation: 'List explanation comes here if any, if not, no display',
        totalReviews:35
      },
      {
        username: 'User’s list name',
        listExplanation: 'List explanation comes here if any, if not, no display',
        totalReviews:35
      },
      {
        username: 'User’s list name',
        listExplanation: 'List explanation comes here if any, if not, no display',
        totalReviews:35
      },
      {
        username: 'User’s list name',
        listExplanation: 'List explanation comes here if any, if not, no display',
        totalReviews:35
      },
      {
        username: 'User’s list name',
        listExplanation: 'List explanation comes here if any, if not, no display',
        totalReviews:35
      },
      {
        username: 'User’s list name',
        listExplanation: 'List explanation comes here if any, if not, no display',
        totalReviews:35
      },
      {
        username: 'User’s list name',
        listExplanation: 'List explanation comes here if any, if not, no display',
        totalReviews:35
      },
      {
        username: 'User’s list name',
        listExplanation: 'List explanation comes here if any, if not, no display',
        totalReviews:35
      },
      {
        username: 'User’s list name',
        listExplanation: 'List explanation comes here if any, if not, no display',
        totalReviews:35
      },
    ],
    center :  { lat: -35.304724, lng: 148.662905 },
    locations : [
    { lat: -31.56391, lng: 147.154312 },
    { lat: -33.718234, lng: 150.363181 },
    { lat: -33.727111, lng: 150.371124 },
    { lat: -33.848588, lng: 151.209834 },
    { lat: -33.851702, lng: 151.216968 },
    { lat: -34.671264, lng: 150.863657 },
    { lat: -35.304724, lng: 148.662905 },
    { lat: -36.817685, lng: 175.699196 },
    { lat: -36.828611, lng: 175.790222 },
    { lat: -37.75, lng: 145.116667 },
    { lat: -37.759859, lng: 145.128708 },
    { lat: -37.765015, lng: 145.133858 },
    { lat: -37.770104, lng: 145.143299 },
    { lat: -37.7737, lng: 145.145187 },
    { lat: -37.774785, lng: 145.137978 },
    { lat: -37.819616, lng: 144.968119 },
    { lat: -38.330766, lng: 144.695692 },
    { lat: -39.927193, lng: 175.053218 },
    { lat: -41.330162, lng: 174.865694 },
    { lat: -42.734358, lng: 147.439506 },
    { lat: -42.734358, lng: 147.501315 },
    { lat: -42.735258, lng: 147.438 },
    { lat: -43.999792, lng: 170.463352 },
  ],
    menu: false,
    menuPosition: '-305px'
  },
  mutations: {
    setLoginModal(state){
      state.loginModal = !state.loginModal
    },
    setSignup1Modal(state){
      state.signup1Modal = !state.signup1Modal
    },
    setEmailSignup(state){
      state.emailSignup = !state.emailSignup
    },
    setCenter(state,payload){
      state.center = payload
    },
    toggleMenu(state){
      state.menu = !state.menu
      if(state.menu){
        state.menuPosition = '0'
      }
      else{
        state.menuPosition = '-305px'
      }
    },
    hideMenu(state){
      state.menu = false
      state.menuPosition = '-305px'
    }
  },
  actions: {
    setCenterLocation({commit} , location,){
    commit('setCenter',location)
    }
  },
  getters:{
    getFeedData:  (state) => {
      return state.feed
    },
    getListData: (state) =>{
      return state.lists
    },
    getLocations: (state) => {
      return state.locations
    },
    getCenter: (state) => {
      return state.center
    }
   
  },
  modules: {
  }
})
