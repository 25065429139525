<template>

  <GoogleMap
   api-key="AIzaSyB8F1I88u1XLOvKUKgZzllskGN9FbBp86o"
   style="width: 100%; height: 100vh"
  :center="center"
  :zoom="8"
  :streetViewControl= false
  :mapTypeControl = false
  :fullscreenControl = false
  >
    <Marker v-for="marker in locations" :key="marker" :options="{position:marker,icon:require('@/assets/images/passivemapicon.png')}" />
    <Marker :options="{position:center,icon:require('@/assets/images/googlemapactive.png')}" />
  </GoogleMap>

</template>

<script>
import { GoogleMap, Marker } from 'vue3-google-map'
export default {
components:{
GoogleMap , Marker
},
computed:{
locations(){
  return this.$store.getters.getLocations
},
center(){
  return this.$store.getters.getCenter
}
},
data(){
    return{

    }
},
mounted(){
}
}

</script>

<style>

</style>