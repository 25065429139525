<template>
  <div class="outer-box" :class="{'black-blur-bg':searchFriendModal}">
      <div class="content">
      <div class="friend-modal flex flex-column">
     <div class="toggle-bar flex justify-center" @click="toggleFriendModal">
      <img src="@/assets/images/dropmodal.png" />
      </div>
      <div v-if="searchFriendModal">
      <div class="desc1 fw-700">
          友達を追加する
      </div>
      <div class="desc2">
          追加方法を選択してください
      </div>
      <div class="search-icons flex justify-center">
          <div class="flex flex-column qr" @click="redirectToQrCode">
          <div class="qr-img"><img src="@/assets/images/qr.png" /> </div> 
          <div class="qr-text">QRコード</div>
          </div>
           <div class="flex flex-column search" @click="redirectToSearchFriend"> 
          <div class="search-img"><img src="@/assets/images/searchfriend.png" /> </div> 
          <div class="search-text">ID検索</div>
          </div>
          <div class="flex flex-column email" @click="redirectToAddBySnsOrEmail">
          <div class="email-img"><img src="@/assets/images/addbyemail.png" /> </div> 
          <div class="email-text">招待</div>
          </div>
      </div>
      </div>
      </div>
      </div>
  </div>
</template>

<script>
export default {
data(){
    return{
        searchFriendModal: false
    }
},
methods:{
    toggleFriendModal(){
      this.searchFriendModal = !this.searchFriendModal
    },
    redirectToSearchFriend(){
        this.$router.push({name: 'SearchFriend' })   
    },
    redirectToAddBySnsOrEmail(){
        this.$router.push({name: 'AddBySNSorEmail'})
    },
    redirectToQrCode(){
        this.$router.push({name: 'AddFriendByQrCode'})
    }
}
}
</script>

<style lang="scss" scoped>
.black-blur-bg{
 background: rgba($color: #000000, $alpha: 0.5);
}
.outer-box{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    .friend-modal{
        background: #FFFFFF;
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 25px 25px 0px 0px;
        position: fixed;
        bottom: 80px;
        width: 375px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        .toggle-bar{
            cursor: pointer;
            margin: 10px 0;
        }
        .desc1{
            margin-top: 10px;
            font-size: 14px;
            color: #000;
        }
        .desc2{
            font-size: 12px;
            color: #b3b3b3;
            margin-top: 20px;
        }
        .search-icons{
            margin-top: 15px;
            gap: 20px;
            margin-bottom: 20px;
            .qr , .search , .email{
                cursor: pointer;
                gap: 5px;
                color: #f47500;
                font-size: 12px;
                font-weight: 500;
            }
        }
    }
}
</style>