<template>
    <div class="container"> 
      <Header title="Friend List" />
      <div class="content">
       <div class="top-box">
           <div class="notification-sec flex justify-center" @click="redirectToFriendRequest" v-if="hasFriendRequests">
            <div class="notification-icon">
                <img src="@/assets/images/combinedshape.png" />
            </div>
            <div class="notification-text">
                1件の友達リクエストがあります
            </div>
           </div>
           <div class="button-and-text">
               <div class="text">
                   友達と一緒にtoastを使ってみましょう！
               </div>
               <div class="button flex justify-center">
                   <button class="submit-btn white">友達を追加する</button>
               </div>
           </div>
       </div>
       <div class="friends">
           <div class="friend flex" v-for="friend in 10" :key="friend" >
           <div class="avatar">
               <img src="@/assets/images/f1.png" />
           </div>
           <div class="other-information">
            <div class="name-and-location flex">
                <div class="name">
                    Usernickname
                </div>
                <div class="location">
                    <img src="@/assets/images/locationmarker.png" />
                    Tokyo
                </div>
            </div>
            <div class="friend-introduction">
                Self introductionSelf introductionSelf introductionSelf introductionSelf introduction
            </div>
           </div>
           <div class="shadow">
               <img src="@/assets/images/shadow12.png" />
           </div>
           </div>
       </div>
       <AddFriendModal />
      </div>
    
      <div class="footer">
          <Footer />
      </div>
    </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import AddFriendModal from '../components/AddFriendModal.vue'
import Header from '../components/Header.vue'
export default {
components:{
    Footer , AddFriendModal , Header
},
data(){
    return{
           totalResturants: 15,
           hasFriendRequests: true
    }
},
 methods:{  
     redirectToFriendRequest(){
         this.$router.push({name: 'FriendRequest'})
     }
    }
}
</script>

<style lang="scss" scoped>

.container{
    .footer{
        max-width: 375px;
        margin: auto;
    }
  .content{
      margin: auto;
      max-width: 375px;
     .top-box{
         background: #fff;
         text-align: center;
         padding: 15px 50px;
         .notification-sec{
              cursor: pointer;
              border-bottom:  1px solid #8F4E4F;
              margin-bottom: 15px;
             .notification-text{
                font-size: 15px;
                color: #8F4E4F;
            }
         }
         .button-and-text{
             .text{
                 font-size: 12px;
                 color: #b3b3b3;
             }
             .button{
                     .submit-btn{
                     width: 100%;
                     padding: 10px 0;
                     border: none;
                     background: #f47500;
                     border-radius: 5px;
                     margin-top: 2px;
                 }
             }
         }
     }
     .friends{
         background: #e5e5e5;
         padding: 15px;
         .friend{
             position: relative;
             &:not(:first-child){
                 margin-top: 15px;
             }
             gap: 10px;
          background: #fff;
          padding: 20px;
          border-radius: 0 3px 3px 0;
          .avatar{

          }
          .other-information{
              .name-and-location{
                  justify-content: space-between;
                  .name{
                      font-size: 15px;
                      color: #000;
                  }
                  .location{
                      color: #b7b7b7;
                      font-size: 14px;
                  }
              }
              .friend-introduction{
                font-size: 12px;
                color: #363636;
              }
          }
          .shadow{
              position: absolute;
              left: 0;
              bottom: -18px;
          }
         }
     }
  }
    
   
}
</style>