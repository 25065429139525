<template>
  <div class="container">
    <Header title="Open List" />
      <div class="content">
      <div class="search-and-filter">
        <img class="search-icon" src="@/assets/images/search-icon.png">
        <img class="sort-icon" src="@/assets/images/sort-icon.png">
       <input type="text" placeholder="Search" />
      </div>
      <div class="lists">
       <div v-for="(list,index) in Lists" :key="index" class="list-card">
        <router-link :to="{ name: 'IndividualList', params: { id: index+1, }}" class="link">
       <div class="user-name orange fw-700">{{list.username}}</div>
       <div class="list-explanation">{{list.listExplanation}}</div>
       <div class="reviews-and-food-categories flex">
       <div class="total-reviews flex">
           <img src="@/assets/images/listcount.png" class="contained" />
           <div class="review-count">{{list.totalReviews}}</div>
       </div>
       <div class="selected-categories flex">
           <img src="@/assets/images/knifeporkspoon.png" class="contained" />
           <div> Selected Food Categories</div>
       </div>
       </div>
       </router-link>
         <div class="shadow">
           <img src="@/assets/images/shadow12.png" />
       </div>
       </div>
      </div>
      </div>
       <div class="footer">
           <Footer />
       </div>
  </div>
</template>

<script>
import Footer from '../components/Footer'
import Header from '../components/Header.vue'
export default {
components:{
    Footer , Header
},
computed:{
    currentScrollPosition(){
        return this.scrollPosition > 10
    },
    Lists(){
        return this.$store.getters.getListData
    }
},
methods:{
}
}
</script>

<style lang="scss" scoped>
.container{
   .footer{
       max-width: 375px;
       margin: auto;
   }
    margin: auto;

    .content{
        max-width: 375px;
        margin:auto;
        .search-and-filter{
            position: relative;
            img{
                position: absolute;
            }
                .search-icon{
              top:10px;
              left:10px;
                }
                .sort-icon{
                    top:10px;
                    right: 10px;
                }
            
        input{
            width: 100%;
            height: 40px;
            padding-left: 40px;
            color: #c9c9c9;
            font-size: 14px;
            border: none;
            &:focus{
                outline: none;
            }
        }
        }
        .lists{
            background: #dddddd;
            padding: 15px;
            .list-card{
                cursor: pointer;
                padding: 12px;
                margin: 15px 0;
                background: #FFFFFF;
                border-radius: 0px 3px 3px 0px;
                position: relative;
                &:first-child{
                    margin-top: 0 !important;
                }
                .user-name{
                    font-size: 18px;
                }
                .list-explanation{
                    font-size: 14px;
                    color: #b7b7b7;
                }
                .reviews-and-food-categories{
                    margin-top: 5px;
                    justify-content: space-between;
                    .total-reviews , .selected-categories{
                        gap: 10px;
                        color: #333333;
                        font-size: 14px;
                    }
                }
                .shadow{
                    position: absolute;
                    left: 0;
                    bottom: -18px;
                }
            }
        }
    }
}
</style>