<template>
  <div class="container" >
     <Header />
      <div class="content">
        <div v-for="(feed,index) in Feeds" :key="index" class="card">
             <div class="top-content flex">
                 <div class="user-info flex">
                  <img :src=  feed.userinfo.img.url />
                  <div class="name">
                      {{feed.userinfo.name}}
                  </div>
                 </div>
                 <div class="post-time-and-state">
                     <div class="time">
                         {{feed.postTtimeAndstate.time}}
                     </div>
                     <div class="state">
                          {{feed.postTtimeAndstate.state}}
                     </div>
                 </div>
             </div>
             <div class="resturant-content">
                 <div class="main-image">
                 <img :src="feed.image.url" />
                 <div class="more-images" v-if="(Object.keys(feed.image).length>1)" @click="showMoreImages"> 
                      <img src="@/assets/images/morepictures.png" class="more-images-icon"> 
                 </div>
                 </div>
                 <div class="name-and-rating flex">
                     <div class="name">
                         {{feed.resturant.name}}
                     </div>
                     <div class="rating flex">
                         <div v-for="(star,index) in Math.round(feed.resturant.rating)" :key="index" class="flex star">
                           <div v-if="star >= feed.resturant.rating">
                               <div v-if="feed.resturant.rating % 1 == 0 ">
                                <img src="@/assets/images/star-1.0.svg" />
                               </div>
                               <div v-else>
                                <img src="@/assets/images/star-0.5.svg" />
                               </div>
                           </div>
                           <div v-else>
                               <img src="@/assets/images/star-1.0.svg" />
                           </div>
                         </div>
                     </div>
                 </div>
                 <div class="comments">
                     <div class="comment">
                     {{feed.comments.comment}}
                     </div>
                 </div>
             </div>
        </div>
      </div>
       <div class="footer">
           <Footer />
       </div>
       <ImageSlider v-if="showSlider" />
  </div>
</template>

<script>
import Footer from '../components/Footer'
import Header from '../components/Header.vue'
import ImageSlider from '../components/ImageSlider.vue'
export default {
components:{
    Footer , Header , ImageSlider
},
computed:{
    Feeds(){
        return this.$store.getters.getFeedData
    }
},
data(){
    return{
        showSlider: false
    }
},
mounted(){
    document.addEventListener('click', elem => this.hideSliderMenu(elem))
},
methods:{
    showMoreImages(){
         let scrollTop = window.pageYOffset || document.documentElement.scrollTop
         let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
         window.onscroll = function() {
         window.scrollTo(scrollLeft, scrollTop);
        };
        this.showSlider = true
    },
    hideSliderMenu(elementtt){
    let sliderArea = elementtt.target.closest('.slider-images')
    let popSliderIcons = elementtt.target.classList.contains('more-images-icon')
     if(!sliderArea && this.showSlider == true && popSliderIcons == false){
         this.showSlider = false
         window.onscroll = function() {}
     }
    }
},
}
</script>

<style lang="scss" scoped>
.container{
    
   .footer{
       max-width: 375px;
       margin: auto;
   }
    margin: auto;
    .top-bar{
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 12px;
        .logo , .menu{
            cursor: pointer;
        }
        .menu{
            position: fixed;
            top: 20px;
            right: 10px;
        }
    }
    .content{
         max-width: 375px;       
         margin: auto;
         padding-bottom: 80px;
       //  margin-top: 20px !important;
         @media(max-width:375px){
             margin-top: 0 !important;
         }
        .card{
        background: #fff;
        margin-bottom: 5px;
        padding-bottom: 20px;
        }
        
        .top-content{
             padding: 12px;
            justify-content: space-between;
            .user-info{
                align-items: center;
                gap:15px;
                .name{
                    font-size: 15px;
                }
            }
            .post-time-and-state{
                .time{
                    font-size: 13px;
                    font-weight: 300;
                    color: #888888;
                    font-style: italic;
                }
                .state{
                    font-size: 15px;
                    color: #6c6c6c;
                }
            }
        }
        .resturant-content{
            .main-image{
                position: relative;
                .more-images{
                 position: absolute;
                 bottom: 0;
                 right: 10px;
                 cursor: pointer;
                }
            }
            .name-and-rating{
                padding: 12px;
                justify-content: space-between;
                .name{
                    font-size: 15px;
                    color: #6c6c6c;
                }
                .rating{
                    .star{
                        div{
                            img{
                                width: 20px;
                            }
                        }
                    }
                }
            }
            .comments{
                padding:  0 12px;
                .comment{
               font-size: 15px;
               color: #6c6c6c;
                }
            }
        }
    }
}
</style>