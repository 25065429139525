<template>
  <div class="email-signup-overlay">
      <div class="container">
      <div class="box flex flex-column">
          <div>
   <div class="close-btn flex" @click="setSignup1">
       <img src="@/assets/images/close.png">
   </div>
   <div class="logo flex flex-column">
       <img src="@/assets/images/logo1.png">
       <div class="description">アカウントを作成</div>
   </div>
   <div class="back-btn" @click="backToSignupHome">
       <img src="@/assets/images/backbtn.png">
   </div>
      <div class="inputs flex flex-column">
        <div class="email">
            <input type="text" placeholder="メールアドレス *">
            <div>
                <img src="@/assets/images/mail.png">
            </div>
        </div>
           <div class="password">
               <input type="password" placeholder="パスワード *">
               <div>
                <img src="@/assets/images/lock.png">
            </div>
           </div>
          </div>
          <div class="login-link flex justify-center"> 
         <div> すでにアカウントをお持ちですか？ </div> <div @click="setLoginModal"> <router-link :to="{name: ''}">Login</router-link> </div>
          </div>
          </div>
          <div class="footer-text">
              * 次のステップへ進むことで、<router-link :to="{name:''}">に同意した</router-link><br>ものとみなします。
          </div>
          </div>
      </div>
       </div>
 
</template>

<script>
export default {
methods:{
    setSignup1(){
     window.onscroll = function() {}
      this.$store.commit('setEmailSignup')
    },
    backToSignupHome(){
        this.$store.commit('setEmailSignup')
        this.$store.commit('setSignup1Modal')
    },
    setLoginModal(){
        this.$store.commit('setEmailSignup')
        this.$store.commit('setLoginModal')
    }
}
}
</script>

<style lang="scss" scoped>
.email-signup-overlay{
position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
    background: rgba(0,0,0,.5);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .container{
        width: 375px;
        padding: 20px;
        .box{
            justify-content: space-between;
            position: relative;
            background: rgba(255, 255, 255, 0.95);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            width: 100%;
            height: 80vh;
            padding: 20px;
            .close-btn{
                justify-content: flex-end;
                cursor: pointer;
            }
            .logo{
                margin-top: 20px;
                align-items: center;
            }
            .back-btn{
                margin-bottom: 10px;
                cursor: pointer;
            }
           .inputs{
               gap: 15px;
               .email , .password{
                   position: relative;
                   width: 100%;
                   input{
                       padding-left: 40px;
                       width: 100%;
                       background: #FFFFFF;
                      border: 1px solid #ADADAD;
                     box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                     border-radius: 5px;
                     height: 36px;
                     &:focus{
                       outline: none;
                     }
                   }
                   div{
                       position: absolute;
                       top: 0;
                       left: 10px;
                       height: 100%;
                       display: flex;
                       align-items: center;
                   }
               }
           }
            .login-link{
                font-size: 12px;
                margin-top: 20px;
            }
            .footer-text{
                text-align: center;
                font-size: 10px;
                color: #555555;
            } 
        }
    }
}

</style>