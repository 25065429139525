<template>
    <div class="container">
      <Header title="Friend detail" /> 
      <div class="content">
          <div class="outer-box">
          <div class="content-box">
          <div class="user-id">
              ID: userid here
          </div>
          <div class="friend-image flex justify-center">
          <img src="@/assets/images/friendimage.png"/>
          </div>
          <form @submit.prevent="submit" class="personal-information">
          <div class="form-control">
              <label>Nickname</label>
              <input type="text" v-model="nickname" />
          </div>
          <div class="form-control">
              <label>Self Introduction</label>
              <input type="text" v-model="selfIntroduction" />
          </div>
          <div class="form-control">
              <label>Location</label>
              <input type="text" v-model="location" />
          </div>
          <div v-if="!isFriend">
          <div class="account-date flex">
            <div class="title">申請：</div>
            <div class="date-and-time flex"> <div class="date">2021/11/02</div> <div class="time"> 11:40 </div></div>
          </div>
          <div class="above-submit-text">
              このユーザーから友達リクエストがきています。承認しますか？
          </div>
          <div class="buttons flex">
              <button class="btn btn1 white">承認する</button>
              <button class="btn btn2">承認しない</button>
          </div>
          </div>
          <div v-else>
              <div class="friend-list">
                  <div class="name fw-700">
                      User’s list name
                  </div>
                  <div class="list-explanation flex">
                      <div class="explanation">List explanation comes here if any, …</div>
                      <div class="view-list-btn">
                          <img src="@/assets/images/list-redirect.png" />
                      </div>
                  </div>
                  <div class="reviews-and-categories flex">
                      <div class="reviews flex">
                          <img src="@/assets/images/listcount.png" /><div>35</div>
                      </div>
                      <div class="categories flex">
                          <img src="@/assets/images/knifeporkspoon.png"><div> Food Categories </div>
                      </div>
                  </div>
              </div>
              <div class="delete-friend-text flex justify-center">
                  友達リストから削除する
              </div>
          </div>
          </form>

          </div> 
          </div>
          </div>

      <Footer />
    </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'
export default {
components:{
Footer , Header
},
data(){
    return{
        nickname: 'Moto',
        selfIntroduction : 'If any, text comes here. ',
        location: 'Prefecture and city name',
        isFriend: false
    }
},
methods:{
}
}
</script>

<style lang="scss" scoped>
.container{
    .footer{
        max-width: 375px;
        margin: auto;
    }
 
   .content{
       max-width: 375px;
       margin: auto;
       .outer-box{
           padding: 15px;
           background: #e5e5e5;
           height: 100vh;
           .content-box{
               background: #FFFFFF;
               border: 1px solid #EAEAEA;
               box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
               border-radius: 5px;
               padding: 15px;
               .user-id{
                   font-size: 12px;
                   color: #BFBFBF;
                   line-height: 14.06px
               }
               .friend-image{
                 margin-top: 10px;
               }
               .personal-information{
                   margin-top: 20px;
                   padding: 0 5px;
                   .form-control{
                       &:not(:first-child){
                           margin-top: 10px;
                       }
                       label{
                           display: inline-block;
                           color: #f47500;
                           font-size: 14px;
                           line-height: 16px;
                       }
                       input{
                           &:focus{
                               outline: none;
                           }
                           width: 100%;
                           border: none;
                           border-bottom: 1px solid #b1b1b1;
                           padding-bottom: 15px;
                       }
                   }
                   .account-date{
                       gap:20px;
                       color: #b7b7b7;
                       font-size: 12px;
                       margin-top: 40px;
                       padding: 0 10px;
                       margin-bottom:10px;
                   }
                   .above-submit-text{
                       color: #333333;
                       font-size: 14px;
                       padding: 0 10px;
                   }
                   .buttons{
                       margin-top: 20px;
                       .btn{
                           border-radius: 5px;
                           padding: 10px 0;
                           border: none;
                           font-size: 18px;
                       }
                       .btn1 , .btn2{
                           flex: 1;
                       }
                       .btn1{
                           background-color: #f47500;
                       }
                       .btn2{
                           border: 1px solid #c0c0c0;
                           color: #838383;
                       }
                   }
                   .friend-list{
                       background: #eeeae6;
                       border-radius: 5px;
                       padding: 20px 10px;
                       margin-top: 20px;
                       .name{
                           font-size: 18px;
                           color: #f47500;
                       }
                       .list-explanation{
                           .explanation{
                               font-size: 14px;
                               color: #b7b7b7;
                           }
                       }
                       .reviews-and-categories{
                           justify-content: space-between;
                           margin-top: 5px;
                           .reviews{
                               gap: 10px;
                           }
                           .categories{
                               gap: 10px;
                           }
                       }
                   }
                   .delete-friend-text{
                       cursor: pointer;
                       font-size: 12px;
                       color: #8F4E4F;
                       margin-top: 15px;
                   }
               }
           }
       }
   }
}
</style>