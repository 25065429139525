<template>
    <div class="container">
      <Header title="Friend,s Comments" />
      <div class="content">
          <div class="comment-and-image flex flex-column">
              <div v-for="(friend,index) in friends" :key="index" class="flex single-comment" >
               <div class="comment flex flex-column justify-center" >
                  <div class="rating flex">
                          <div v-for="(star) in Math.round(friend.rating)" :key="star" class="flex star">
                           <div v-if="star >= friend.rating">
                               <div v-if="friend.rating % 1 == 0 ">
                                <img src="@/assets/images/star-1.0.svg" />
                               </div>
                               <div v-else>
                                <img src="@/assets/images/star-0.5.svg" />
                               </div>
                           </div>
                           <div v-else>
                               <img src="@/assets/images/star-1.0.svg" />
                           </div>
                         </div>
                   </div>
                  
                  <div class="text" v-if="friend.comment!=null">
                     {{friend.comment}}
                  </div>
                  <div class="images-and-date flex">
                      <div class="images flex" v-if="friend.images!=null"> 
                          <img src="@/assets/images/camera.png" />
                          <div>{{friend.images}} </div>
                     </div>
                          <div class="date">
                             <div>{{friend.publishedDate}} </div>
                          </div>
                     
                  </div>
              </div> 
              <div class="image flex flex-column">
               <img :src="friend.pic" />
               <div>{{friend.nickname}}</div>
              </div>
          </div>
          </div>
          </div>
      
      <div class="footer">
          <Footer />
      </div>
   </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'
export default {
components:{
    Footer , Header
},
data(){
    return{
       friends : [
           {
               rating: 3.5,
               nickname: 'nickname' ,
               comment: 'Comment comes here, a user can add multiple comments under one review if he wants. ',
               images: 4,
               publishedDate: '2021/09/12',
               pic: require('@/assets/images/commentusericon.png')
           },
            {
               rating: 5,
               nickname: 'nickname' ,
               comment: 'Comment comes here, a user can add multiple comments under one review if he wants. ',
               images: 8,
               publishedDate: '2021/09/12',
               pic: require('@/assets/images/person1.png')
           },
           {
               rating: 3.5,
               nickname: 'nickname' ,
               comment: null,
               images: null,
               publishedDate: null,
               pic: require('@/assets/images/person2.png')
           },
           {
               rating: 3.5,
               nickname: 'nickname' ,
               comment: 'Comment comes here, a user can add multiple comments under one review if he wants. ',
               images: 4,
               publishedDate: '2021/09/12',
               pic: require('@/assets/images/commentusericon.png')
           },
            {
               rating: 5,
               nickname: 'nickname' ,
               comment: 'Comment comes here, a user can add multiple comments under one review if he wants. ',
               images: 8,
               publishedDate: '2021/09/12',
               pic: require('@/assets/images/person1.png')
           },
           {
               rating: 3.5,
               nickname: 'nickname' ,
               comment: null,
               images: null,
               publishedDate: null,
               pic: require('@/assets/images/person2.png')
           },
       ]
    }
},
}
</script>

<style lang="scss" scoped>
.container{
    .footer{
        max-width: 375px;
        margin: auto;
    }
    .content{
        max-width: 375px;
        margin: auto;
        background: #fff;
        .comment-and-image{
            padding: 15px;
        .single-comment{
            margin-top: 20px;
            gap: 10px;
        .comment{
            padding: 5px;
            gap: 5px;
            background: #FFFFFF;
            border: 1px solid #DFDFDF;
            border-radius: 5px;
            width: 100%;
            .rating{
                gap: 5px;
                .star{
                    img{
                        width: 30px;
                    }
                }
            }
            .text{
                font-size: 12px;
                color: #363636;
                margin: 6px 0;
                line-height: 14px;
            }
        .images-and-date{
            justify-content: space-between;
            .images{
                gap: 10px;
                div{
                    font-size: 14px;
                    color: #b8b8b8;
                }
            }
            .date{
                font-size: 12px;
                color: #d3d3d3;
            }
        }
    }
         .image{
             div{
                 font-size: 12px;
                 color: #676767;
             }
         }
            }
        }
    }
}
</style>