<template>
    <div class="container"> 
     <Header title="Add Friend" />
     <div class="content">
      <div class="outer-box">
          <div class="content-box">
             <div class="title">
                 toastを利用中の友達をIDで検索できます！
             </div>
             <form @submit.prevent="submit">
             <div class="search-bar">
                 <input type="search" placeholder="ユーザーIDを入力" v-model="searchedId">
             </div>
             <div class="search-button flex justify-center" @click="searchUsers">
                 <button class="search-btn white">検索</button>
             </div>
             </form>
             
          </div>
          <div class="filteredUser flex" v-if="filteredUser" >
                 <div class="avatar">
                     <img :src=filteredUser.avatar :alt=filteredUser.id />
                 </div>
                 <div class="other-information">
                     <div class="name-and-location flex">
                         <div class="name">{{filteredUser.name}} </div>
                         <div class="location"> <img src="@/assets/images/locationmarker.png" />  {{filteredUser.location}}    </div>
                     </div>
                     <div class="self-introduction">
                     {{filteredUser.introduction}}
                 </div>
                 </div>
                 <div class="shadow">
                     <img src="@/assets/images/shadow12.png" />
                 </div>
             </div>
             <div v-if="noResults" class="flex justify-center noresults">
                 該当するユーザーが見つかりません
             </div>
      </div>
     </div>
    
      <div class="footer">
          <Footer />
      </div>
    </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'
export default {
components:{
    Footer , Header
},
computed:{
},
data(){
    return{
           searchedId: '',
           noResults: false,
           users:[
               {id:36463,
               name: 'Usernickname',
               introduction: 'Self introductionSelf introductionSelf introductionSelf introductionSelf introduction',
               location: 'Tokyo',
               avatar: require('@/assets/images/f1.png')
               },
               {id:36462,
               name: 'Usernickname',
               introduction: 'Self introductionSelf introductionSelf introductionSelf introductionSelf introduction',
               location: 'Tokyo',
               avatar: require('@/assets/images/f1.png')
               },
               {id:36464,
               name: 'Usernickname',
               introduction: 'Self introductionSelf introductionSelf introductionSelf introductionSelf introduction',
               location: 'Tokyo',
               avatar: require('@/assets/images/f1.png')
               },
               {id:36465,
               name: 'Usernickname',
               introduction: 'Self introductionSelf introductionSelf introductionSelf introductionSelf introduction',
               location: 'Tokyo',
               avatar: require('@/assets/images/f1.png')
               },
               {id:36469,
               name: 'Usernickname',
               introduction: 'Self introductionSelf introductionSelf introductionSelf introductionSelf introduction',
               location: 'Tokyo',
               avatar: require('@/assets/images/f1.png')
               },
               {id:36468,
               name: 'Usernickname',
               introduction: 'Self introductionSelf introductionSelf introductionSelf introductionSelf introduction',
               location: 'Tokyo',
               avatar: require('@/assets/images/f1.png')
               },
               {id:36467,
               name: 'Usernickname',
               introduction: 'Self introductionSelf introductionSelf introductionSelf introductionSelf introduction',
               location: 'Tokyo',
               avatar: require('@/assets/images/f1.png')
               },
           ],
           filteredUsers: [],
           filteredUser : null
    }
},
 methods:{
    searchUsers(){
        this.filteredUsers = []
        this.filteredUser = []
        this.filteredUsers =  this.users.filter((user)=>{
          return user.id == this.searchedId
        })
        this.filteredUsers.length > 0 ? this.noResults = false : this.noResults = true
        this.filteredUser = this.filteredUsers[0]
    }
    }
}
</script>

<style lang="scss" scoped>

.container{
    .footer{
        max-width: 375px;
        margin: auto;
    }
 .content{
     max-width: 375px;
     margin: auto;
     .outer-box{
         background: #e5e5e5;
         height: 100vh;
         padding: 15px;
         .content-box{
             background: #FAFAFA;
             box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
             border-radius: 5px;
             padding: 20px;
             .title{
                 margin-top: 10px;
                 font-size: 14px;
                 color: #333;
             }
             .search-bar{
                 margin-top: 20px;
                 input{
                     background: #F5F5F5;
                     box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
                     border-radius: 5px;
                     width: 100%;
                     border: none;
                     height: 46px;
                     padding-left: 15px;
                     font-size: 20px;
                     font-weight: 500;
                     color: #000;
                     &:focus{
                         outline: none;
                     }
                 }
             }
             .search-button{
                 .search-btn{
                     margin-top: 20px;
                     border: none;
                     padding: 10px 0;
                     background: #f47500;
                     width: 80%;
                     cursor: pointer;
                     border-radius: 5px;
                 }
             }
            
         }
          .filteredUser{
                 margin-top: 20px;
                 background: #fff;
                 padding: 20px;
                 gap: 10px;
                 position: relative;
                 .name-and-location{
                     justify-content: space-between;
                     .name{
                         font-size: 15px;
                         color: #000;
                     }
                     .location{
                         font-size: 14px;
                         color: #b7b7b7;
                     }
                 }
                 .self-introduction{
                     font-size: 12px;
                     color: #363636;
                 }
                 .shadow{
                     position: absolute;
                     bottom: -18px;
                     left: 0;
                 }
        }
        .noresults{
            margin-top: 20px;
            color: #beb1a2;
            font-size:14px;
            font-weight: 500;
        }
     }
 }
   
}
</style>