<template>
    <div class="container">
      <Header title="Edit List"/>
      <div class="content">
          <div class="outer-box">
              <div class="content-box">
                <form class="information">
                    <div class="form-control">
                        <label for="list-name">List display name</label>
                        <input type="text" id="list-name" v-model="listName">
                    </div>
                     <div class="form-control">
                        <label for="list-name">Favourite cuisin</label>
                        <input type="text" value="html,input,tag" data-role="tagsinput" />
                    </div>
                     <div class="form-control">
                        <label for="list-explanation">List explanation</label>
                        <textarea type="text" id="list-explanation" v-model="listExplanation" maxlength="300">
                        </textarea>
                    </div>
                    <div class="form-control">
                        <label for="list-access">Access</label>
                        <select id="list-access" v-model="listAccess" value="Friend only">
                        <option >Public</option>
                        <option value="Friend only">Friend</option>
                        <option>Private</option>
                        </select>
                    </div>
                    <div class="submit-button flex justify-center">
                        <button class="submit-btn white">更新する</button>
                    </div>
                </form>
              </div>
          </div>
      </div>
      
      <div class="footer">
          <Footer />
      </div>
    </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'
export default {
components:{
    Footer , Header
},
computed:{
     currentScrollPosition(){
        return this.scrollPosition > 10
    },
},
data(){
    return{
           listName: 'User’s list name',
           listCuisines: '',
           listExplanation: 'List explanation comes here. List explanation comes here. List explanation comes here. List explanation comes here.',

    }
},
}
</script>

<style lang="scss" scoped>

.container{
    .footer{
        max-width: 375px;
        margin: auto;
    }
    .content{
        max-width: 375px;
        margin: auto;
        .outer-box{
            background: #e5e5e5;
            padding: 15px;
            height: 100vh;
            .content-box{
                background: #FFFFFF;
                border: 1px solid #EAEAEA;
                box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
                border-radius: 5px;
                padding: 20px;
                .information{
                   
                    .form-control{
                         &:not(:first-child){
                        margin-top: 15px;
                    }
                        label{
                            display: block;
                            font-size: 14px;
                            color: #f47500;
                            margin-bottom: 10px;
                        }
                        input , select , textarea{
                            width: 100%;
                            border:none;
                            border-bottom: 1px solid #b1b1b1;
                            padding-bottom: 10px;
                            &:focus{
                                outline: none;
                            }
                        }
                        
                        textarea{
                            height: 80px
                        }
                    }
                     .submit-button{
                            margin-top: 30px;
                           
                            .submit-btn{
                               border: none;
                               background: #f47500;
                               border-radius: 5px;
                               display: block;
                               width: 80%;
                               padding: 10px 0;
                            }
                        }
                }
            }
        }
    }
   
}
</style>