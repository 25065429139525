<template>
    <div class="container">
     <Header title="レビューする" />
      <div class="content">
         <div class="box-outer">
         <div class="box">
    <form class="search-form" @submit.prevent="submit">
        <div class="form-control">
        <input type="search" v-model="resturant" placeholder="お店を入力" @change="filterResults" >
        <div class="delete-input" @click="clearSearchBar">
            <img src="@/assets/images/deleteinput.png" />
        </div>
        </div>
        <button v-if="filteredResturants.length == 0 && currentResturant == null" type="submit" ><div class="flex justify-center"><img src="@/assets/images/btnicon.png" />お店を選択してください。 </div></button>
    </form>
    <div class="filteredResturants" v-if="filteredResturants.length > 0">
        <div v-for="resturant in filteredResturants" :key="resturant" @click="showResturant(resturant)">
            <div class="flex resturant-and-prefacture">
                <div class="resturant">{{resturant.name}}</div>
                <div class="slash">/</div>
                <div class="prefacture">{{resturant.prefecture}}</div>
            </div>
        </div>
     </div>
     <div class="resturant-review-detail"  v-if="currentResturant !== null">
          <div class="current-resturant flex" v-if="currentResturant !== null" >
              <div class="information flex flex-column">
                  <div class="category flex">
                <div class="category-icon">    <img src="@/assets/images/knifeporkspoon.png" />  </div>
                 <div class="category-text">{{currentResturant.category}}</div></div>
                 <div class="address flex">
                <div class="address-icon">    <img src="@/assets/images/mapicon.png" />  </div>
                <div class="address-text">{{currentResturant.address}}</div></div>
                <div class="friend flex">
                <div class="friend-icon">    <img src="@/assets/images/btnicon.png" />  </div>
                <div class="friend-text">{{currentResturant.friendReviews }} 名の友達が話題にしています</div></div>
              </div>
           </div>
           <div class="get-feedback">
               <div class="title text-center">
                   How was it?
               </div>
               <div class="get-rating flex justify-center" >
                   <star-rating v-model:rating="rating"
                   :rating="0"
                   :max-rating="5"
                   :increment="0.5"
                   inactive-color="#c0c0c0"
                   :rounded-corners="true"
                   :border-color="null"
                   >
                   </star-rating>
               </div>
          
           <div class="upload-pictures" @click="enablePictures" v-if="addPictures">
               <div class="flex image-and-text">
                   <div class="image">
                       <img src="@/assets/images/addpicture.svg" />
                   </div>
                   <div class="text">
                       <div>想い出フード<br>
                        画像を追加！</div>
                   </div>
               </div>
           </div>
            <div class="pictures flex" v-if="uploadPictures">
                <div class="image-icon">
                <img src="@/assets/images/uploadpictures.png" />
                </div>
                <div class="boxes flex">
                    <div class="filebox flex" v-for="(label,index) in uploadedPictures" :key="index" @click="updateFeaturedImage(index)">
                    <div class="delete-picture" @click="deleteUploadedPicture(index)">
                    <img src="@/assets/images/deleteinput2.png" />
                    </div>
                    <div class="featured-image" v-if="checkForFeaturedImage(index)">
                        <img src="@/assets/images/featuredimage.png" />
                    </div>
                    </div>
                    <label class="upload-image-title">
                        <form method="post" enctype="multipart/form-data">
                        <input type="file" @change="previewFiles()" multiple ref="myfiles"/>
                        </form>
                        <div class="title">+ 画像追加</div>
                    </label>
                </div>
             </div>
              <div class="featured-image-info flex" v-if="uploadedPictures.length > 0">
                 <img src="@/assets/images/green-checkmark.svg" />
                 <div>代表画像</div>
                </div>
           <div class="comment-date-accesses">
               <div class="add-more flex justify-center" v-if="addMore" @click="showCommentDateAccesses">
               <img src="@/assets/images/addmore.svg" />
               </div>
               <div class="comment-date-accesses-content" v-if="CommentDateAccesses">
                   <div class="comment flex" v-for="(comment,index) in comments" :key="index">
                       <img src="@/assets/images/commentvector.png" />
                       <input type="text" placeholder="詳細を追加" v-model="comment.comment" class="textarea" role="textbox"  @keyup="autogrow" >
                   </div>
                   <div @click="addComment" class="add-comment flex">Add Comment</div>
                   <div class="date flex" v-for="(date,index) in dates" :key="index">
                       <img src="@/assets/images/calendar1.png" />
                       <input type="date" placeholder="日付を追加" v-model="date.date" >
                   </div>
                   <div @click="addDate" class="add-date flex">Add Date</div>
                   <div class="dates">
                   <div class="selected-dates" v-for="(date,index) in dates" :key="index">
                       <div class="single-date" v-if="date.date!='date'">
                        <div class="flex date-and-cross">  <div class="cross-date"> {{date.date}} </div> <div class="cross" @click="getConfirmation()"> <img src="@/assets/images/deleteinput1.png" /> </div> </div>
                       </div>
                   </div>
                   </div>
                   <div class="access flex">
                    <div class="select-options">
                        <li v-if="publicaccess" @click="changeAccessMode(1)" :class="{'list-item-background': menuOpen}"> <div class="flex list-item"> <img src="@/assets/images/public.png"  /> <span>公開  </span>  </div>   </li>
                        <li v-if="friendaccess" @click="changeAccessMode(2)" :class="{'list-item-background': menuOpen}">  <div class="flex list-item">  <img src="@/assets/images/access-world.svg" /> <span>友達のみ </span> </div>    </li>
                        <li v-if="privateaccess" @click="changeAccessMode(3)" :class="{'list-item-background': menuOpen}"> <div class="flex list-item">   <img src="@/assets/images/access-private.svg" /> <span> 私のみ  </span> </div>   </li>
                        <div class="dropdown" v-if="!menuOpen">
                            <img src="@/assets/images/selectdropdown.svg" />
                        </div>
                   </div>
                  </div>
               </div>
           </div>
            </div>
            <div class="submit-review flex justify-center">
                <button class="submit-btn white">投稿する</button>
            </div>
          </div>
         </div>
          </div>
    
      <div class="footer">
          <Footer />
      </div>
   </div>
      </div>
    <DeleteConfirmation name="Date" v-if="deleteConfirmation" @close-modal-no="handleClick('no')" @close-modal-yes="handleClick('yes',index)" />
</template>

<script>
import Footer from '../components/Footer.vue'
import StarRating from 'vue-star-rating'
import DeleteConfirmation from '../components/DeleteConfirmation.vue'
import Header from '../components/Header'
export default {
components:{
    Footer , StarRating , DeleteConfirmation , Header
},
computed:{
},

data(){
    return{
       resturant: '',
       resturants: [           //Dummy Data
                {name: 'Tokyo Japanese Bar',prefecture:'Tokyo',category: 'Sushi, Bar, Ramen',address:'Restaurant address comes here, open google map in browser',friendReviews:3},
                {name: 'Tokyo Jazzy Night',prefecture:'Tokyo',category: 'Sushi, Bar, Ramen',address:'Restaurant address comes here, open google map in browser',friendReviews:3},
                {name: 'Tokyo Jamaica Food',prefecture:'Tokyo',category: 'Sushi, Bar, Ramen',address:'Restaurant address comes here, open google map in browser',friendReviews:3},
                {name: 'Tokyo J Soul Brothers',prefecture:'Tokyo',category: 'Sushi, Bar, Ramen',address:'Restaurant address comes here, open google map in browser',friendReviews:3},
                {name: 'Tokyo Joy and Sushi Resturant',prefecture:'Tokyo',category: 'Sushi, Bar, Ramen',address:'Restaurant address comes here, open google map in browser',friendReviews:3},
                {name: 'Tokyo Joy and Sushi Resturant',prefecture:'Tokyo',category: 'Sushi, Bar, Ramen',address:'Restaurant address comes here, open google map in browser',friendReviews:3},
                {name: 'karachi bar',prefecture:'Tokyo',category: 'Sushi, Bar, Ramen',address:'Restaurant address comes here, open google map in browser',friendReviews:3},
                {name: 'new york bar',prefecture:'Tokyo',category: 'Sushi, Bar, Ramen',address:'Restaurant address comes here, open google map in browser',friendReviews:3},
       ],
       filteredResturants: [],
       currentResturant: null,
       rating: 4,              // Dummy rating set for the review, actual rating from the db will replace this
       addMore: false ,
       CommentDateAccesses : false,
       dates:[{date: 'date'}],
       deleteConfirmation : false,
       addPictures: true,
       uploadPictures: false,
       uploadedPictures: [] ,
       featuredImageIndex: 0,
       comments: [{comment: ''}  ],
       publicaccess: true,
       friendaccess: false,
       privateaccess: false,
       menuOpen: false
    }
},
 methods:{
       filterResults(){
          this.filteredResturants =  this.resturants.filter((resturant)=>{
              if(this.filteredResturants.length <=5 ){
              return  resturant.name.toLowerCase().indexOf(this.resturant.toLowerCase()) != -1
              }
        }) 
       },
       clearSearchBar(){
           this.resturant = ''
           this.filteredResturants = []
           this.currentResturant = null
           this.CommentDateAccesses = false
           this.dates = [{date: 'date'}]
           this.addPictures = true
           this.uploadPictures = false
           this.uploadedPictures = []
       },
       showResturant(obj){
          this.filteredResturants = []
          this.currentResturant = obj
          this.addMore = true
       },
       showCommentDateAccesses(){
        this.addMore = false
        this.CommentDateAccesses = true
       },
       addDate(){
           this.dates.push({date:'date'})
       },
       deleteDate(index){
           this.dates.splice(index,1)
       },
       getConfirmation(){
         let scrollTop = window.pageYOffset || document.documentElement.scrollTop
         let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
         window.onscroll = function() {
         window.scrollTo(scrollLeft, scrollTop);
        };
           this.deleteConfirmation = true
       },
       handleClick(data , index){
           if(data == 'no'){
               this.deleteConfirmation = false
               window.onscroll = function() {}
               return
           }
           this.deleteConfirmation = false
           this.deleteDate(index)
           window.onscroll = function() {}
       },
       previewFiles(){
           for(let file = 0; file < this.$refs.myfiles.files.length; file++){
           this.uploadedPictures.push(this.$refs.myfiles.files[file])
           }
       },
       enablePictures(){
           this.addPictures = false
           this.uploadPictures = true
       },
       deleteUploadedPicture(index){
           this.uploadedPictures.splice(index,1)
           this.checkForFeaturedImage(this.featuredImageIndex)
       },
       checkForFeaturedImage(index){
        return  index == this.featuredImageIndex ?  true : false
       },
       updateFeaturedImage(index){
           this.featuredImageIndex = index
           this.checkForFeaturedImage(index)
       },
       addComment(){
           this.comments.push({comment:''})
           console.log(this.comments)
       },
       autogrow(){
    this.$refs.comment.style.height = this.calcHeight(this.$refs.comment.style.height.value) + "px";
       },
       calcHeight(value){
       let numberOfLineBreaks = (value.match(/\n/g) || []).length;
       let newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2;
       return newHeight;
       },
       changeAccessMode(mode){
           if(this.menuOpen == false){
           this.friendaccess = true
           this.privateaccess = true
           this.publicaccess = true
           this.menuOpen = true
           return
           }
           
           if(this.menuOpen == true){
               if(mode == 1){
                   this.friendaccess = true
                   this.privateaccess = false
                   this.friendaccess = false
               }
              else if(mode == 2){
                   this.friendaccess = true
                   this.privateaccess = false
                   this.publicaccess = false
               }
              else if(mode == 3){
                   this.friendaccess = false
                   this.privateaccess = true
                   this.publicaccess = false
               }
               this.menuOpen = false
           }
           
       }
    }
}
</script>

<style lang="scss" scoped>
.textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  min-height: 40px;
  line-height: 20px;
}
.list-item-background{
    &:hover{
        box-shadow: 0 0 10px 100px #1882A8 inset; 
    }
}
.container{
    .footer{
        max-width: 375px;
        margin: auto;
    }
   .content{
       
       max-width: 375px;
       margin: auto;
       .box-outer{
           background: #e5e5e5;
           padding: 15px;
           .box{
            background: #FAFAFA;
            box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
            border-radius: 5px;
            min-height: 80vh;
            padding: 20px 10px;
            padding-bottom: 100px !important;
            .search-form{
                .form-control{
                    position: relative;
                    .delete-input{
                        cursor: pointer;
                        position: absolute;
                        top: 0;
                        right: 10px;
                        display: flex;
                        align-items: center;
                        height: 100%;
                    }
                    input{
                        width: 100%;
                        border: none;
                        height: 46px;
                        background: #F5F5F5;
                        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
                        border-radius: 5px;
                        padding-left: 15px;
                        &:focus{
                            outline: none;
                        }
                    }
                    input[type="search"]::-webkit-search-cancel-button {
                    display: none;
                    }
                }
                button{
                    margin-top:10px;
                    width: 100%;
                    background: #EEEAE6;
                    border-radius: 5px;
                    padding: 10px 0;
                    border: none;
                    cursor: pointer;
                    div{
                        gap: 10px;
                        align-items:center;
                        img{
                            height: 100%;
                        }
                    }
                }
            }
            .filteredResturants{
                background: #FFFFFF;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
                border-radius: 5px;
                padding: 10px;
                .resturant-and-prefacture{
                    margin-top: 10px;
                    cursor: pointer;
                    .resturant{
                        font-size: 15px;
                        color: #333333;
                    }
                    .prefacture , .slash{
                      font-size: 15px;
                      color: #bdbdbd;
                    }
                    .slash{
                        margin: 0 5px;
                    }
                }
            }
            .resturant-review-detail{
            .current-resturant{
                margin-top: 20px;
                background: #EEEAE6;
                border-radius: 5px;
                padding: 10px;
                gap: 10px;
                .information{
                 .category , .address , .friend {
                     gap: 10px;
                     align-items: flex-start;
                 }
                 .friend{
                     align-items: center !important;
                 }
                 .address{
                     margin: 5px 0;
                 }
                  .category-text{
                      font-size: 15px;
                      color: #838383;
                      text-transform: capitalize;
                  }
                  .address-text{
                      font-size: 14px;
                      color: #333333;
                  }
                  .friend-text{
                      font-size: 14px;
                  }
                
                }
                
            }
            .get-feedback{
                margin-top: 30px;
                .title{
                    color: #333333;
                    font-size: 18px;
                }
                .get-rating{
                    margin-top: 30px;
                    .vue-star-rating{
                    .vue-star-rating{
                      .vue-star-rating-star{
                          svg{
                          width: 40px !important;
                          }
                      }
                        }
                    }
                }
                .upload-pictures{
                    cursor: pointer;
                    background: #ECECEC;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
                    border-radius: 10px;
                    padding: 10px;
                    margin-top: 30px;
                    .image-and-text{
                        gap: 20px;
                        align-items: center;
                        .text{
                            font-size: 18px;
                            color: #868686;
                            line-height: 21px;
                        }
                    }
                }
                .pictures{
                    margin-top: 30px;
                    gap: 30px;
                    .boxes{
                        flex-wrap: wrap;
                        gap: 30px;
                        align-items:center;
                        .filebox{
                         position: relative;
                         background: #ECECEC;
                         border: 2px solid #B5B5B5;
                         border-radius: 5px;
                         height: 80px;
                         width: 100px;
                         cursor: pointer;
                         display: inline-block;
                        }
                        .delete-picture{
                            position: absolute;
                            top: 5px;
                            right: 8px;
                        //    z-index: 100;
                        }
                        .featured-image{
                            position: absolute;
                            top: 10px;
                            left:10px;
                        }
                         input[type="file"]{
                           display: none;
                        }
                        .upload-image-title{
                            cursor: pointer;
                        }
                        
                    }
                }
                .featured-image-info{
                    gap: 10px;
                    margin-left: 50px;
                    margin-top: 15px;
                }
                .comment-date-accesses{
                    margin-top: 30px;
                    padding: 0 15px;
                    .add-more{
                        cursor: pointer;
                    }
                    .comment-date-accesses-content{
                        .comment{
                            gap: 15px;
                            align-items: center;
                            input{
                                width: 100%;
                                border: none;
                                height: 36px;
                                padding-left: 10px;
                                background: transparent;
                                &:focus{
                                    outline: none;
                                }
                            }
                            &:not(:first-child){
                                margin-top: 10px;;
                            }
                        }
                        .add-comment{
                            margin-top: 10px;
                            justify-content: flex-end;
                            cursor: pointer;
                        }
                        .date{
                            gap: 15px;
                            align-items: center;
                            margin: 10px 0;
                            input{
                                width: 100%;
                                border: none;
                                height: 36px;
                                padding-left: 10px;
                                background: transparent;
                                &:focus{
                                    outline: none;
                                }
                            }
                        }
                        .add-date{
                            justify-content: flex-end;
                            cursor: pointer;
                        }
                        .dates{
                        .selected-dates{
                            &:last-child{
                                margin-bottom: 20px;
                            }
                          .single-date{
                              display: inline-block;
                              border: 1px solid #888888;
                              border-radius: 20px;
                              padding: 2px 5px;
                              margin-top: 10px;
                              .date-and-cross{
                                  align-items: center;
                                  gap: 5px;
                                  .cross{
                                      cursor: pointer;
                                  }
                              }
                          }
                        }
                        }
                        .access{
                            justify-content: space-between;
                            align-items: flex-start;
                            gap: 15px;
                            .images{
                                gap: 10px;
                                margin-right: 10px;
                            }
                            .select-options{
                                flex: 5;
                                position: relative;
                                .dropdown{
                                    position: absolute;
                                    top: 2px;
                                    right: 0;
                                }
                                .list-item{
                                    gap: 10px;
                                    align-items: center;
                                    img{
                                        max-width: 26px;
                                    }
                                }
                                li{
                                    &:not(:first-child){
                                        margin-top: 10px;
                                    }
                                    list-style: none;
                                    cursor: pointer;
                                }
                            }
                            
                        }
                    }
                }
               
            }
             .submit-review{
                    width: 100%;
                    margin-top: 40px;
                    .submit-btn{
                        width: 80%;
                        background: #f47500;
                        border: none;
                        border-radius: 5px;
                        padding: 10px 0;
                        cursor: pointer;
                    }
                }
           }
           }
       }
   }
}
</style>