<template>
    <div class="container">
      <Header title="Map" />
      <div class="content">
          <div class="map">
             <GoogleMap />
             <div class="listname-and-filter flex">
                 <div class="listname flex">
                     <img src="@/assets/images/marker.png" class="contained" />
                     <div class="name" >List Name</div> 
                     <img src="@/assets/images/dropdown.png" @click="toggleListModal" style="cursor:pointer" class="contained" />
                 </div>
                 <div class="filter">
                     <img src="@/assets/images/sort-map.svg "  />
                 </div>
             </div>
             <div class="resturant">
                 <div class="hide-menu flex justify-center" @click="toggleResturantsContent" >
                     <img src="@/assets/images/hideline.png" />
                 </div>
                 <div v-if="resturantContent" class="hideandshowcontent" >
                 <div class="image-and-content flex">
                     <div class="featured-image">
                         <img src="@/assets/images/featuredresturant.png" />
                     </div>
                     <div class="resturant-content">
                     <div class="rating-and-name flex">
                         <div class="rating white fw-700 text-center">{{resturants[currentResturant].rating}}</div>
                         <div class="name">{{resturants[currentResturant].name}}</div>
                     </div>
                     <div class="images-and-texts flex">
                     <div class="images flex flex-column">
                         <div class="category-image">
                         <img src="@/assets/images/knifeporkspoon.png" />
                         </div>
                         <div class="location-image"><img src="@/assets/images/location.png" /></div>
                         <div class="comment-image"><img src="@/assets/images/comment.png" /></div>
                     </div>
                     <div class="texts flex flex-column">
                         <div class="category-text flex"><div>{{resturants[currentResturant].category}}</div> <div class="flex camera-content">
                             <img src="@/assets/images/camera.png" class="contained" />
                             <div>{{resturants[currentResturant].images}}</div>
                             </div> </div>
                         <div class="location-text">{{resturants[currentResturant].location}}</div>
                         <div class="comment-text">{{resturants[currentResturant].comments}}</div>
                     </div>
                     </div>
                     </div>
                 </div>
                 <div class="arrow left-arrow" @click="changeResturant('previous')">
                 <img src="@/assets/images/leftarrow.png" />
                 </div>
                 <div class="arrow right-arrow" @click="changeResturant('next')">
                 <img src="@/assets/images/rightarrow.png" />
                 </div>
             </div>
             </div>
             </div>
             <ListNames v-if="showListModal" v-on:closelist="toggleListModal" />
      </div>
    </div>
</template>

<script>
import GoogleMap from '../components/GoogleMap.vue'
import ListNames from '../components/ListNames.vue'
import Header from '../components/Header'
export default {
components:{
    GoogleMap,
    ListNames,
    Header
},
computed:{
    Locations(){
        return this.$store.getters.getLocations
    }
},
created(){
this.listName = this.$route.params.listname
},
data(){
    return{
        scrollPosition: null,
        listName: '',
        resturantContent: true,
        showListModal: false,
        currentResturant: 0,
        resturants:[
            {
                name: 'Restaurant name here',
                rating: 4.5,
                category: 'Sushi, Bar, Ramen',
                images: 25,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
             {
                name: 'Restaurant name here',
                rating: 3.5,
                category: 'Sushi, Bar, Ramen',
                images: 45,
                location:'location here',
                comments: 'if any comments, display that ...'
            }, {
                name: 'Restaurant name here',
                rating: 5.0,
                category: 'Sushi, Bar, Ramen',
                images: 65,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
             {
                name: 'Restaurant name here',
                rating: 4.5,
                category: 'Sushi, Bar, Ramen',
                images: 25,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
             {
                name: 'Restaurant name here',
                rating: 4.5,
                category: 'Sushi, Bar, Ramen',
                images: 25,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
             {
                name: 'Restaurant name here',
                rating: 4.5,
                category: 'Sushi, Bar, Ramen',
                images: 25,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
             {
                name: 'Restaurant name here',
                rating: 4.5,
                category: 'Sushi, Bar, Ramen',
                images: 25,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
             {
                name: 'Restaurant name here',
                rating: 4.5,
                category: 'Sushi, Bar, Ramen',
                images: 25,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
             {
                name: 'Restaurant name here',
                rating: 4.5,
                category: 'Sushi, Bar, Ramen',
                images: 25,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
             {
                name: 'Restaurant name here',
                rating: 4.5,
                category: 'Sushi, Bar, Ramen',
                images: 25,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
             {
                name: 'Restaurant name here',
                rating: 4.5,
                category: 'Sushi, Bar, Ramen',
                images: 25,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
             {
                name: 'Restaurant name here',
                rating: 4.5,
                category: 'Sushi, Bar, Ramen',
                images: 25,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
             {
                name: 'Restaurant name here',
                rating: 4.5,
                category: 'Sushi, Bar, Ramen',
                images: 25,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
             {
                name: 'Restaurant name here',
                rating: 4.5,
                category: 'Sushi, Bar, Ramen',
                images: 25,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
             {
                name: 'Restaurant name here',
                rating: 4.5,
                category: 'Sushi, Bar, Ramen',
                images: 25,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
             {
                name: 'Restaurant name here',
                rating: 4.5,
                category: 'Sushi, Bar, Ramen',
                images: 25,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
             {
                name: 'Restaurant name here',
                rating: 4.5,
                category: 'Sushi, Bar, Ramen',
                images: 25,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
             {
                name: 'Restaurant name here',
                rating: 4.5,
                category: 'Sushi, Bar, Ramen',
                images: 25,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
             {
                name: 'Restaurant name here',
                rating: 4.5,
                category: 'Sushi, Bar, Ramen',
                images: 25,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
             {
                name: 'Restaurant name here',
                rating: 4.5,
                category: 'Sushi, Bar, Ramen',
                images: 25,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
             {
                name: 'Restaurant name here',
                rating: 4.5,
                category: 'Sushi, Bar, Ramen',
                images: 25,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
             {
                name: 'Restaurant name here',
                rating: 4.5,
                category: 'Sushi, Bar, Ramen',
                images: 25,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
             {
                name: 'Restaurant name here',
                rating: 4.5,
                category: 'Sushi, Bar, Ramen',
                images: 25,
                location:'location here',
                comments: 'if any comments, display that ...'
            },
        ]
    }
},
 methods:{
    toggleResturantsContent(){
        this.resturantContent = !this.resturantContent
    },
    toggleListModal(){
        this.showListModal = !this.showListModal
    },
    changeResturant(Resturant){
        if(Resturant == 'previous'){
            if(this.currentResturant == 0 ){
            return
        }
            this.currentResturant--
            this.$store.dispatch('setCenterLocation',this.Locations[this.currentResturant])
        }
        else if(Resturant == 'next'){
             if(this.currentResturant == this.Locations.length -1){
            return
        }
            this.currentResturant++
            this.$store.dispatch('setCenterLocation' , this.Locations[this.currentResturant])

        }
    }
    }
}
</script>

<style lang="scss" scoped>
.container{
    .content{
        max-width: 375px;
        margin: auto;
        height: 100vh;
        .map{
        position: relative;
        .listname-and-filter{
            position: absolute;
            top: 10px;
            left: 10px;
            width: 95%;
            justify-content: space-between;
            .listname{    
                gap: 5px;
                background: #FFFFFF;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 30px;
                padding: 10px;
            }
        }
        .resturant{
            padding: 10px;
            background: #FFFFFF;
            box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
            border-radius: 25px 25px 0px 0px;
            position: relative;
            position: absolute;
            bottom: 0;
            width: 100%;
            .hideandshowcontent{
                transition: 2s;
            }
            .arrow{
                cursor: pointer;
                position: absolute;
                top: 50%;
            }
            .left-arrow{
                left:10px;
            }
            .right-arrow{
                right:10px;
            }
            .hide-menu{
                cursor: pointer;
            }
            .image-and-content{
                margin-top: 10px;
                gap:10px;
                .resturant-content{
                    .rating-and-name{
                        gap: 10px;
                        .rating{
                            font-size: 18px;
                            font-style: italic;
                            padding: 6px;
                            border-radius: 50%;
                            background: linear-gradient(224.31deg, #F57600 19.14%, #FFB82F 80.17%);
                            min-width: 40px;
                        }
                        .name{
                            font-size: 18px;
                            color: #333333;
                        }
                    }
                   .images-and-texts{
                       margin-top: 10px;
                       gap:10px;
                       .images{
                        align-items: center;
                       }
                       .texts{
                           .category-text , .location-text , .comment-text{
                               font-size: 15px;
                               color: #838383;
                           }
                           .category-text{
                               justify-content: space-between;
                               .camera-content{
                                   gap: 10px;
                               }
                           }
                       }
                   }
            }
        }
    }
    }
}
}
</style>