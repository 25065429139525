<template>
  <div class="wrapper"> 
    <div class="menuWrapper" :style="{right: menuPosition}">
      <Menu />
    </div>
      <router-view/>
       <img src="./assets/images/f2.png" class="absolute-img1 absolutes-images" v-if="isHome">
       <img src="./assets/images/f.png" class="absolute-img2 absolutes-images" v-if="isHome">
       <img src="./assets/images/f3.png" class="absolute-img3 absolutes-images" v-if="isHome">
       
  </div>
</template>
<script>
import Menu from './components/Menu'
export default{
  components:{
  Menu
  },
  computed:{
    isHome(){
     return this.$route.name == 'Home'
    },
    menuPosition(){
      return this.$store.state.menuPosition
    },
  },
  watch:{
    $route(){
      this.$store.commit('hideMenu')
    }
  }
}
</script>
<style lang="scss">
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}
.container{
  width: 100%;
  padding: 0 !important;
}
.btn:focus{
  outline: none;
}
.form-control{
  height: unset;
  box-shadow: unset;
  padding: unset;
  -webkit-box-shadow: unset;
  border: unset;
}
.btn{
  margin-left: 0 ;
  margin-right: 0;
}
.bootstrap-tagsinput{
  border: none !important;
  box-shadow: none;
  width: 100%;
  input{
  border-bottom: 1px solid #b1b1b1;
  width: 100%;
  }
 }
 .bootstrap-tagsinput .tag {
    margin-right: 10px;
    color: #333333;
    font-size: 14px;
    border: 1px solid #8b8b8b;
    border-radius: 20px;
    padding: 2px 5px;
    font-weight: 400;
    background: transparent;
}
.wrapper{
  min-height: 100vh;
  position: relative;
  background: rgb(234, 237, 240);
  margin: auto;
  overflow: hidden;
  .absolutes-images{
  position: absolute;
 
  }
    .absolute-img1{
      top: 130px;
      left: -30px;
      @media (min-width:1200px) {
        width: 250px;
      }
  }
   .absolute-img2{
      top: 35%;
      right: -30px;
       @media (min-width:1200px) {
        width: 250px;
      }
  }
    .absolute-img3{
       @media (min-width:1200px) {
        width: 310px;
      }
      @media(max-width:520px){
        top: 55%;
      }
      top: 65%;
      left: -30px;
  }
  .menuWrapper{
    transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    position: absolute;
    top: 0;
    right: 0;
    width: 305px;
    z-index: 1000;
  }
}


/**Helpers */
.fw-700{
  font-weight: 700;
}
.fw-bold{
  font-weight: bold;
}
.white{
  color: white;
}
.orange{
  color: #FF9B3F !important;
}
.bg-orange{
  background-color: #FF9B3F !important;
}
.orange-fill{
  fill: #FF9B3F;
}
.black{
  color:#000;
}
.link{
  text-decoration: none;
}
.link:hover{
  text-decoration: none;
}
.black-stroke{
  stroke: #000 !important;
}
.flex{
  display: flex;
}
.flex-1{
  flex: 1;
}
.justify-center{
  justify-content: center;
}
.flex-column{
  flex-direction: column;
}
.text-center{
  text-align: center;
}
.drop-shadow{
  box-shadow: 0px 4px 15px #000;
}
.text-shadow{
  text-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
}
.img-100{
  width: 100%;
}
.contained{
  object-fit: contain;
}
</style>
