<template>
    <div class="container"> 
      <div class="top-bar bg-orange flex">
          <div class="back-btn" @click="back()">
          <img src="@/assets/images/back.png">
          </div>
          <div class="open-list-text white">
           Add Friend
          </div>
          <div class="menu" :class="{'changePosition':currentScrollPosition}">
              <img src="@/assets/images/white-menu.png">
          </div>
      </div>
      <div class="content">
          <div class="outer-box">
              <div class="content-box">
               <div class="tabs flex justify-center">
                   <div class="sns-tab" @click="changeTab('sns')"> 
                       <div class="title" :class="{'orange':snsTabActive,'tab-border-bottom':snsTabActive}">SNSで招待</div>
                   </div>
                   <div class="email-tab" @click="changeTab('email')">
                       <div class="title" :class="{'orange':emailTabActive, 'tab-border-bottom':emailTabActive}">メールで招待</div>
                   </div>       
               </div>
               <div class="sns-tab-content" v-if="snsTabActive">
                       <div class="input-title">
                         友達にメールを送信して、一緒にtoastを使ってみましょう！
                       </div> 
                       <input type="text" v-model="url" placeholder="メールアドレスを入力" />
                       <div class="send-email-btn flex justify-center">
                          <a href="mailto:{{url}}" class="send-email"> <button class="copy-btn white">メールを送信する</button> </a>
                       </div>
                   </div>
               <div class="email-tab-content" v-if="emailTabActive">
                       <div class="input-title">
                         友達にメールを送信して、一緒にtoastを使ってみましょう！
                       </div> 
                       <input type="text" v-model="url" placeholder="メールアドレスを入力" />
                       <div class="send-email-btn flex justify-center">
                          <a href="mailto:{{url}}" class="send-email"> <button class="copy-btn white">メールを送信する</button> </a>
                       </div>
                   </div>
              </div>
          </div>
      </div>
      <div class="footer">
          <Footer />
      </div>
    </div>
</template>

<script>
import Footer from '../components/Footer.vue'
export default {
components:{
    Footer
},
computed:{
     currentScrollPosition(){
        return this.scrollPosition > 10
    },
},
data(){
    return{
           scrollPosition: null,
           snsTabActive: true,
           emailTabActive: false,
           url: ''
    }
},
mounted(){
    document.addEventListener('scroll',this.Scrollll)
},
 methods:{
        back(){
            this.$router.go(-1)
        },
        Scrollll(){
        this.scrollPosition = window.scrollY
    },
    changeTab(tab){
        if(tab=='sns'){
            this.snsTabActive = true
            this.emailTabActive = false
            return
        }
        this.emailTabActive = true
        this.snsTabActive = false
    }
    }
}
</script>

<style lang="scss" scoped>

.container{
    .footer{
        max-width: 375px;
        margin: auto;
    }
  .top-bar{
        justify-content: space-between;
        align-items: center;
        padding: 12px;
        .open-list-text{
            font-size: 18px;
            font-weight: 500;
        }
        .back-btn , .menu{
            cursor: pointer;
        }
        .changePosition{
            position: fixed;
            top: 20px;
            right: 10px;
        }
    }
    .content{
    max-width: 375px;
    margin: auto;
    .outer-box{
        background: #e5e5e5;
        padding: 15px;
        height:100vh;
        .content-box{
            padding: 20px;
            background: #FAFAFA;
            box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
            border-radius: 5px;
            .tabs{
                gap: 30px;
                .sns-tab{
                    .title{
                        padding-bottom:3px;
                        cursor: pointer;
                        font-size: 14px;
                        color: #b7b7b7;
                    }
                }
                .email-tab{
                    .title{
                        cursor: pointer;
                        padding-bottom: 3px;
                        font-size: 14px;
                        color: #b7b7b7;
                    }
                }
            }
             .sns-tab-content{
                margin-top: 30px;
                .title{
                    font-size: 14px;
                    color: #333;
                }
                    input{
                        width: 100%;
                        height: 46px;
                        background: #F5F5F5;
                        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
                        border-radius: 5px;
                        border: none;
                        margin: 20px 0;
                        padding-left: 10px;
                        &:focus{
                            outline: none;
                        }
                    }
                    .send-email-btn{
                        .send-email{
                           width: 80%;
                            .copy-btn{
                          background: #f47500;
                            border-radius: 5px;
                            width: 100%;
                            border: none;
                            padding: 10px 0;
                            }
                        }
                    }
            }
            .email-tab-content{
                margin-top: 30px;
                .title{
                    font-size: 14px;
                    color: #333;
                }
                    input{
                        width: 100%;
                        height: 46px;
                        background: #F5F5F5;
                        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
                        border-radius: 5px;
                        border: none;
                        margin: 20px 0;
                        padding-left: 10px;
                        &:focus{
                            outline: none;
                        }
                    }
                    .send-email-btn{
                        .send-email{
                           width: 80%;
                            .copy-btn{
                          background: #f47500;
                            border-radius: 5px;
                            width: 100%;
                            border: none;
                            padding: 10px 0;
                            }
                        }
                    }
            }
        }
    }
    }
}
.tab-border-bottom{
    border-bottom: 3px solid #f47500;
}
</style>