<template>
    <div class="container">
        <div>
     <Header title="Review" />
      <div class="content">
        <div class="images">
      <div v-if="images == 5">   
              <img src="@/assets/images/i6.png" />
          <div class="flex">
              <img src="@/assets/images/35.png" />
              <img src="@/assets/images/36.png" />
          </div>
          <div class="flex">
              <img src="@/assets/images/37.png" />
              <img src="@/assets/images/38.png" />
          </div>
      </div>
       <div v-if="images == 4">   
              <img src="@/assets/images/i6.png" />
              <img src="@/assets/images/351.png" />
          <div class="flex">
              <img src="@/assets/images/36.png" />
              <img src="@/assets/images/38.png" />
          </div>
      </div>
       <div v-if="images == 3">   
              <img src="@/assets/images/i6.png" />
          <div class="flex">
              <img src="@/assets/images/36.png" />
              <img src="@/assets/images/38.png" />
          </div>
      </div>
       <div v-if="images == 2">   
              <img src="@/assets/images/i6.png" />
              <img src="@/assets/images/40.png" />
      </div>
       <div v-if="images == 1">   
              <img src="@/assets/images/i6.png" />
      </div>
      </div>
      <div class="flex review-btn" @click="pushToEditReview">
          <button class="write-review flex"><img src="@/assets/images/listcount.png" />
          <div class="text">編集</div></button>
      </div> 
      <div class="resturant-name">
          Restaurant name here
      </div>

         <div class="rating flex justify-center">
                         <div v-for="(star) in Math.round(rating)" :key="star" class="flex star">
                           <div v-if="star >= rating">
                               <div v-if="rating % 1 == 0 ">
                                <img src="@/assets/images/star-1.0.svg" />
                               </div>
                               <div v-else>
                                <img src="@/assets/images/star-0.5.svg" />
                               </div>
                           </div>
                           <div v-else>
                               <img src="@/assets/images/star-1.0.svg" />
                           </div>
                         </div>
        </div>
        <div class="comments flex" >
            <div class="user flex flex-column">
             <img src="@/assets/images/commentusericon.png" />
            <div> nickname </div>
            </div>
            <div class="flex flex-column all-comments" v-if="comments >= 1">
            <div class="comment" v-for="comment in comments" :key="comment">
              Comment comes here, a user can add multiple comments under one review if he wants. 
              <div class="date-published flex">
                  2021/09/20
              </div>
            </div>
            </div>
            <div v-else class="flex justify-center no-review">
                <div class="">
                 No comment...
                </div>
                <img src="@/assets/images/noreview.png" />
            </div>
        </div>
        <div class="friend-comments" v-if="hasFriendComments">
           
            <div class="title text-center">
                Friend,s comments
            </div>
             <div v-for="comment in friendComments" :key="comment">
            <div class="comments-and-users flex">
                <div class="comment ">
                    <div class="rating flex">
                          <div v-for="(star) in Math.round(rating)" :key="star" class="flex star">
                           <div v-if="star >= rating">
                               <div v-if="rating % 1 == 0 ">
                                <img src="@/assets/images/star-1.0.svg" />
                               </div>
                               <div v-else>
                                <img src="@/assets/images/star-0.5.svg" />
                               </div>
                           </div>
                           <div v-else>
                               <img src="@/assets/images/star-1.0.svg" />
                           </div>
                         </div>
                   </div>
                   <div class="text">
                       Comment comes here, a user can add multiple comments under one review if he wants. 
                   </div>
                   <div class="date-and-reviews flex">
                       <div class="total-reviews flex">
                        <img src="@/assets/images/camera.png" />
                        <div>5</div>
                       </div>
                       <div class="date">
                           2021/09/12
                       </div>
                   </div>
                </div>
                <div class="user flex flex-column">
                    <img src="@/assets/images/commentusericon.png" />
                    <div>nickname</div>
                </div>
            </div>
             </div>
            <div class="more-comments text-center">
                See more friend’s comments
            </div>
        </div>
        <div class="resturant-information">
            <div class="logo flex justify-center">
                <img src="@/assets/images/logo3.png" />
            </div>
            <div class="text-center title">
                Restaurant Information
            </div>
            <div class="resturant">
                <div class="name fw-700">Restaurant name here</div>
                <div class="category flex">
                    <img src="@/assets/images/knifeporkspoon.png" />
                    <div>Sushi, Bar, Ramen</div>
                </div>
                <div class="address flex">
                    <img src="@/assets/images/mapicon.png" />
                    <div>Restaurant address comes here, open google map in browser</div>
                </div>
                <div class="phone flex">
                    <img src="@/assets/images/phoneicon.png" />
                    <div>000-1111-2222</div>
                </div>
                <div class="website flex">
                    <img src="@/assets/images/globeicon.png" />
                    <div>www.restaurant-website.com</div>
                </div>

            </div>
        </div>
        
      </div>
      </div>
      <div class="footer">
          <Footer />
         
      </div>
    </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'
export default {
components:{
    Footer , Header
}, 
computed:{
},
data(){
    return{
        images: 5,
        rating:4.5,
        comments: 3,
        friendComments : 3,
        hasFriendComments: true
    }
},
 methods:{
    pushToEditReview(){
        this.$router.push({name:'EditReview',params:{'resturantname':'Resturant name here'}})
    }
    }
}
</script>

<style lang="scss" scoped>
.container{
    .footer{
        max-width: 375px;
        margin: auto;
    }
  
    .content{
        max-width: 375px;
        margin: auto;
        background: #fff;
        padding-bottom: 20px;
        .review-btn{
justify-content: flex-end;
margin: 15px;
        .write-review{
            cursor: pointer;
            gap: 10px;
            border: 1px solid #DADADA;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 25px;
            padding: 7px;
        }
        }
        .resturant-name{
            font-size: 20px;
            font-weight: 500;
            text-align: center;
        }
        .rating{
            gap: 10px;
            padding: 20px 0;
            img{
                width: 50px;
            }
        }
        .comments{
            padding: 20px;
            gap: 10px;
            .user{
                align-items: center;
                img{
                    width: 50px;
                }
                div{
                    font-size: 12px;
                    color: #676767;
                }
            }
            .all-comments{
                gap: 15px;
            }
            .comment{
                padding: 12px;
                background: #FFF7E1;
                box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
                border-radius: 5px;
                font-size: 12px;
                color: #363636;
                .date-published{
                    margin-top:10px;
                    font-size: 12px;
                    color: #CCBF9C;
                    justify-content: flex-end;
                    align-items: flex-end;
                }
            }
            .no-review{
                width: 100%;
                justify-content: center;
                align-items: center;
                gap: 10px;
            }
        }
        .friend-comments{
            background: #f3f3f3;
            padding: 30px 20px;
            .title{
                font-size: 18px;
                color: #888888;
            }
            .comments-and-users{
            margin-top: 30px;
            gap:10px;
            .comment{
                background: #FFFFFF;
                border: 1px solid #DFDFDF;
                border-radius: 5px;
                padding: 10px;
                .rating{
                    padding: 0;
                    max-width: 50%;
                    .star{
                        img{
                            width: 30px;
                        }
                    }
                }
                .text{
                    margin: 10px 0;
                }
                .date-and-reviews{
                    justify-content: space-between;
                    .total-reviews{
                        gap:10px;
                        justify-content: center;
                        align-items: center;
                        div{
                            font-size: 14px;
                            color: #d8d8d8;
                        }
                    }
                    .date{
                     font-size: 12px;
                     color: #d3d3d3;
                    }
                }
            }
            .user{
                align-items: center;
                div{
                    font-size: 12px;
                    color: #676767;
                }
            }
            }
            .more-comments{
                font-size: 14px;
                color: #7d7d7d;
                margin-top: 30px;
                cursor: pointer;
            }
        }
        .resturant-information{
            padding: 30px 20px;
            .title{
                font-size: 18px;
                color: #888888;
                margin-top: 12px;
            }
            .resturant{
                margin-top: 20px;
                .name{
                   font-size: 20px;
                }
                .category , .phone , .address , .website{
                    margin-top:12px;
                    gap:10px;
                    font-size: 14px;
                    color: #333333;
                    align-items: center;
                }
            }
        }
    }
}
</style>