<template>
   <div class="bottom-nav flex">
       <div class="item1" :class="{'orange':tabs[0].tab}" @click="changeTab(0)">
           <div class="icon">
             <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="レイヤー_1" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enable-background="new 0 0 64 64" xml:space="preserve">
<g>
	<g>
		<path fill="#14171C" :class="{'orange-fill' : tabs[0].tab}" d="M138.938,57.472c-0.607,0-1.199-0.239-1.64-0.68c-0.598-0.597-0.822-1.469-0.591-2.278l4.374-15.312    c0.108-0.379,0.312-0.725,0.591-1.003l29.528-29.528c1.461-1.46,3.403-2.266,5.47-2.266c2.064,0,4.006,0.806,5.468,2.266    l3.28,3.28c1.461,1.461,2.266,3.403,2.266,5.469s-0.805,4.009-2.266,5.469L155.89,52.415c-0.277,0.28-0.623,0.482-1.002,0.591    l-15.312,4.376C139.366,57.442,139.151,57.472,138.938,57.472z M145.379,41.056l-3.063,10.717l10.718-3.063l29.104-29.103    c0.584-0.585,0.906-1.36,0.906-2.187c0-0.827-0.322-1.604-0.906-2.188l-3.281-3.281c-1.17-1.169-3.208-1.169-4.376,0    L145.379,41.056z"/>
	</g>
	<g>
		
			<rect x="166.203" y="17.833" transform="matrix(0.7072 0.707 -0.707 0.7072 65.183 -117.0789)" fill="#14171C" width="15.467" height="4.64"/>
	</g>
</g>
<g>
	<g>
		<path fill="#14171C" :class="{'orange-fill' : tabs[0].tab}" d="M202.354,62.098c-0.452,0-0.901-0.132-1.287-0.39c-0.646-0.431-1.033-1.154-1.033-1.931V11.832    c0-0.938,0.563-1.781,1.428-2.142l18.561-7.734c0.569-0.237,1.214-0.237,1.784,0l17.668,7.362l17.668-7.362    c0.715-0.297,1.532-0.219,2.179,0.212c0.645,0.431,1.033,1.154,1.033,1.931v47.947c0,0.936-0.563,1.78-1.427,2.141l-18.561,7.734    c-0.571,0.238-1.215,0.238-1.785,0l-17.668-7.362l-17.667,7.362C202.959,62.04,202.656,62.098,202.354,62.098z M220.914,49.725    c0.304,0,0.608,0.059,0.893,0.178l17.668,7.362l16.239-6.768V7.579l-15.347,6.395c-0.571,0.238-1.215,0.238-1.785,0l-17.668-7.362    l-16.24,6.767v42.92l15.349-6.395C220.308,49.784,220.611,49.725,220.914,49.725z"/>
	</g>
	<g>
		<rect x="218.594" y="5.645" fill="#14171C" width="4.641" height="46.4"/>
	</g>
	<g>
		<rect x="237.155" y="11.832" fill="#14171C" width="4.639" height="46.4"/>
	</g>
</g>
<path fill="#14171C" :class="{'orange-fill' : tabs[0].tab}" d="M55.121,60.516H41.2c-2.132,0-3.866-1.733-3.866-3.867V37.316H26.507v19.333  c0,2.134-1.733,3.867-3.866,3.867H8.721c-2.133,0-3.867-1.733-3.867-3.867V24.907c0-1.171,0.521-2.267,1.429-3.003L27.044,5.036  c2.749-2.233,7.005-2.233,9.755,0l20.762,16.87c0.906,0.734,1.427,1.83,1.427,3.001v31.742  C58.987,58.783,57.253,60.516,55.121,60.516z M41.974,55.876h12.373V25.273L33.87,8.636c-1.1-0.895-2.8-0.895-3.899,0L9.494,25.273  v30.604h12.373V36.542c0-2.133,1.734-3.866,3.866-3.866h12.374c2.133,0,3.866,1.733,3.866,3.866V55.876z"/>
<path fill="#14171C" :class="{'orange-fill' : tabs[0].tab}" d="M94.447,59.719c-0.569,0-1.144-0.126-1.683-0.387c-1.352-0.649-2.191-1.984-2.191-3.484V25.811h-6.961  c-2.132,0-3.866-1.733-3.866-3.866v-9.28c0-4.689,3.817-8.507,8.507-8.507h29.388c4.689,0,8.506,3.817,8.506,8.507v43.184  c0,1.5-0.84,2.835-2.191,3.484c-1.351,0.65-2.917,0.472-4.092-0.467l-11.503-9.203l-11.504,9.203  C96.151,59.429,95.304,59.719,94.447,59.719z M94.816,8.798c0.257,0.727,0.396,1.508,0.396,2.32v43.119l10.73-8.583  c1.422-1.143,3.41-1.142,4.835,0.001l10.729,8.582V12.664c0-2.132-1.734-3.866-3.866-3.866H94.816z M84.387,21.171h6.187V11.118  c0-1.28-1.041-2.32-2.32-2.32c-2.133,0-3.866,1.734-3.866,3.866V21.171z"/>
</svg>
           </div>
           <div class="text">
               ホーム
           </div>
       </div>
       <div class="item2" @click="changeTab(1)" :class="{'orange':tabs[1].tab}">
           <div class="icon">
               <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="レイヤー_1" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enable-background="new 0 0 64 64" xml:space="preserve">
<g>
	<g>
		<path fill="#14171C" :class="{'orange-fill' : tabs[1].tab}" d="M78.605,57.472c-0.607,0-1.199-0.239-1.64-0.68c-0.598-0.597-0.822-1.469-0.591-2.278l4.374-15.312    c0.108-0.379,0.312-0.725,0.591-1.003l29.528-29.528c1.461-1.46,3.403-2.266,5.47-2.266c2.064,0,4.006,0.806,5.468,2.266    l3.28,3.28c1.461,1.461,2.266,3.403,2.266,5.469s-0.805,4.009-2.266,5.469L95.556,52.415c-0.277,0.28-0.623,0.482-1.002,0.591    l-15.312,4.376C79.033,57.442,78.818,57.472,78.605,57.472z M85.045,41.056l-3.063,10.717l10.718-3.063l29.104-29.103    c0.584-0.585,0.906-1.36,0.906-2.187c0-0.827-0.322-1.604-0.906-2.188l-3.281-3.281c-1.17-1.169-3.208-1.169-4.376,0    L85.045,41.056z"/>
	</g>
	<g>
		
		<rect x="105.869" y="17.833" transform="matrix(0.7072 0.707 -0.707 0.7072 47.5155 -74.4205)" fill="#14171C" width="15.467" height="4.64"/>
	</g>
</g>
<g>
	<g>
		<path fill="#14171C" :class="{'orange-fill' : tabs[1].tab}" d="M142.021,62.098c-0.452,0-0.901-0.132-1.287-0.39c-0.646-0.431-1.033-1.154-1.033-1.931V11.832    c0-0.938,0.563-1.781,1.428-2.142l18.561-7.734c0.569-0.237,1.214-0.237,1.784,0l17.668,7.362l17.668-7.362    c0.715-0.297,1.532-0.219,2.179,0.212c0.645,0.431,1.033,1.154,1.033,1.931v47.947c0,0.936-0.563,1.78-1.427,2.141l-18.561,7.734    c-0.571,0.238-1.215,0.238-1.785,0l-17.668-7.362l-17.667,7.362C142.625,62.04,142.323,62.098,142.021,62.098z M160.581,49.725    c0.304,0,0.608,0.059,0.893,0.178l17.668,7.362l16.239-6.768V7.579l-15.347,6.395c-0.571,0.238-1.215,0.238-1.785,0l-17.668-7.362    l-16.24,6.767v42.92l15.349-6.395C159.974,49.784,160.278,49.725,160.581,49.725z"/>
	</g>
	<g>
		<rect x="158.26" y="5.645" fill="#14171C" width="4.641" height="46.4"/>
	</g>
	<g>
		<rect x="176.822" y="11.832" fill="#14171C" width="4.639" height="46.4"/>
	</g>
</g>
<path fill="#14171C" :class="{'orange-fill' : tabs[1].tab}" d="M23.114,59.719c-0.569,0-1.144-0.126-1.683-0.387c-1.352-0.649-2.191-1.984-2.191-3.484V25.811h-6.961  c-2.132,0-3.866-1.733-3.866-3.866v-9.28c0-4.689,3.817-8.507,8.507-8.507h29.388c4.689,0,8.506,3.817,8.506,8.507v43.184  c0,1.5-0.84,2.835-2.191,3.484c-1.351,0.65-2.917,0.472-4.092-0.467l-11.503-9.203l-11.504,9.203  C24.818,59.429,23.97,59.719,23.114,59.719z M23.483,8.798c0.257,0.727,0.396,1.508,0.396,2.32v43.119l10.73-8.583  c1.422-1.143,3.41-1.142,4.835,0.001l10.729,8.582V12.664c0-2.132-1.734-3.866-3.866-3.866H23.483z M13.053,21.171h6.187V11.118  c0-1.28-1.041-2.32-2.32-2.32c-2.133,0-3.866,1.734-3.866,3.866V21.171z"/>
</svg>
           </div>
           <div class="text">
               リスト
           </div>
       </div>
       <div class="item3" @click="changeTab(2)" :class="{'orange':tabs[2].tab}">
           <div class="icon">
               <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="レイヤー_1" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enable-background="new 0 0 64 64" xml:space="preserve">
<path  fill="#14171C" :class="{'orange-fill' : tabs[2].tab}" d="M55.585,11.951l-3.28-3.28c-1.462-1.46-3.403-2.266-5.468-2.266c-2.066,0-4.009,0.806-5.47,2.266  L11.839,38.199c-0.279,0.278-0.482,0.624-0.591,1.003L6.875,54.514c-0.231,0.81-0.007,1.682,0.591,2.278  c0.44,0.44,1.032,0.68,1.64,0.68c0.213,0,0.428-0.029,0.638-0.09l15.312-4.376c0.379-0.108,0.725-0.311,1.002-0.591l29.529-29.526  c1.461-1.46,2.266-3.403,2.266-5.469S57.046,13.412,55.585,11.951z M23.201,48.709l-10.718,3.063l3.063-10.717l23.088-23.09  l7.656,7.655L23.201,48.709z M52.305,19.606l-2.734,2.734l-7.657-7.656l2.734-2.734c1.168-1.169,3.206-1.169,4.376,0l3.281,3.281  c0.584,0.584,0.906,1.36,0.906,2.188C53.211,18.246,52.889,19.021,52.305,19.606z"/>
<g>
	<g>
		<path fill="#14171C" d="M142.021,62.098c-0.452,0-0.901-0.132-1.287-0.39c-0.646-0.431-1.033-1.154-1.033-1.931V11.832    c0-0.938,0.563-1.781,1.428-2.142l18.561-7.734c0.569-0.237,1.214-0.237,1.784,0l17.668,7.362l17.668-7.362    c0.715-0.297,1.532-0.219,2.179,0.212c0.645,0.431,1.033,1.154,1.033,1.931v47.947c0,0.936-0.563,1.78-1.427,2.141l-18.561,7.734    c-0.571,0.238-1.215,0.238-1.785,0l-17.668-7.362l-17.667,7.362C142.625,62.04,142.323,62.098,142.021,62.098z M160.581,49.725    c0.304,0,0.608,0.059,0.893,0.178l17.668,7.362l16.239-6.768V7.579l-15.347,6.395c-0.571,0.238-1.215,0.238-1.785,0l-17.668-7.362    l-16.24,6.767v42.92l15.349-6.395C159.974,49.784,160.278,49.725,160.581,49.725z"/>
	</g>
	<g>
		<rect x="158.26" y="5.645" fill="#14171C" width="4.641" height="46.4"/>
	</g>
	<g>
		<rect x="176.822" y="11.832" fill="#14171C" width="4.639" height="46.4"/>
	</g>
</g>
</svg>
           </div>
           <div class="text">
              レビュー
           </div>
       </div>
       <div class="item4" @click="changeTab(3)" :class="{'orange':tabs[3].tab}">
           <div class="icon">
               <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="レイヤー_1" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enable-background="new 0 0 64 64" xml:space="preserve">
<path :class="{'orange-fill' : tabs[3].tab}" d="M61.154,2.167c-0.646-0.431-1.464-0.509-2.179-0.212L41.308,9.318L23.64,1.956  c-0.57-0.237-1.215-0.237-1.784,0L3.295,9.69c-0.864,0.36-1.428,1.204-1.428,2.142v47.946c0,0.776,0.388,1.5,1.033,1.931  c0.386,0.258,0.835,0.39,1.287,0.39c0.302,0,0.604-0.059,0.893-0.178l17.667-7.362l17.668,7.362c0.57,0.238,1.214,0.238,1.785,0  l18.561-7.734c0.863-0.36,1.427-1.205,1.427-2.141V4.098C62.188,3.322,61.799,2.598,61.154,2.167z M25.067,7.578l13.921,5.801v42.92  l-13.921-5.801V7.578z M6.507,13.377l13.92-5.8v42.92l-13.92,5.799V13.377z M57.547,50.498l-13.92,5.801v-42.92l13.92-5.8V50.498z"/>
</svg>
           </div>
           <div class="text">
               マップ
           </div>
       </div>
      </div>
</template>

<script>
export default {
data(){
    return{
         tabs: [{tab:true},{tab:false},{tab:false},{tab:false}]
    }
},
methods:{
     changeTab(tab){
       for(let i=0;i<this.tabs.length;i++){
           if(i==tab){
               this.tabs[i].tab = true
               if(tab == 0){
                   this.$router.push({name:'Home'})
               }
               else if(tab == 2){
                   this.$router.push({name:'NewReview'})
               }
               continue
           }
           this.tabs[i].tab = false
       }
       
}
}
}
</script>

<style lang="scss" scoped>
     .bottom-nav{
                 position: fixed;
                 bottom: 0;
                 left: 50%;
                 transform: translateX(-50%);
                 background: #fff;
                 box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.15);
                 width: 375px;
                 padding: 10px 0;
                 .item1 , .item2 , .item3 , .item4{
                     flex: 1;
                     display: flex;
                     flex-direction: column;
                     align-items: center;
                     cursor: pointer;
                     .icon{
                         svg{
                             width: 35px;
                             height: 35px;
                         }
                     }
                 }
}
</style>