<template>
    <div class="container"> 
    <Header title="Share List" />
    <div class="content">
    <div class="outer-box">
        <div class="content-box">
        <div class="title flex justify-center">
             リンクを作成しました！
        </div> 
        <div class="link">
        <input type="text" v-model="link" disabled>
        </div>
        <div class="date-and-time flex justify-center">
            <div class="date">2021/11/08</div>
            <div class="time">0:34</div>
            <div class="desc">まで有効</div>
        </div>
        <div class="share-button">
            <button class="share-btn">
                <div class="flex justify-center button-inner-content">
                    <div class="share-icon"> <img src="@/assets/images/sharelink.png" />  </div>
                    <div class="share-text white"> シェアする  </div>
                </div>
            </button>
        </div>
        <div class="reload-link flex justify-center">
            <div class="reload-image">
              <img src="@/assets/images/reload.png" />
              </div>
               <div class="relaod-text">
                   リンクを作成しなおす
               </div>
        </div>
        </div>
    </div>
    </div>
      <div class="footer">
          <Footer />
         
      </div>
    </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'
export default {
components:{
    Footer , Header
},
computed:{
},
data(){
    return{
           link: 'http://toast-list/0110e221w…'
    }
},

}
</script>

<style lang="scss" scoped>

.container{
    .footer{
        max-width: 375px;
        margin: auto;
    }
    .content{
        max-width: 375px;
        margin: auto;
  .outer-box{
      padding: 20px;
      background: #e5e5e5;
      height: 100vh;
      .content-box{
       background: #FFFFFF;
       border: 1px solid #EAEAEA;
       box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
       border-radius: 5px;
       padding: 30px 10px;
       .title{
           font-size: 18px;
           color: #f47500;
       }
       .link{
           margin-top: 20px;
           input{
               width: 100%;
               background: #F5F5F5;
               box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
               border-radius: 5px;
               border: none;
               height: 46px;
               padding-left: 10px;
               font-size: 20px;
               color: #bdbdbd;
           }
       }
       .date-and-time{
           gap: 10px;
           font-size: 18px;
           color: #333;
           align-items: flex-end;
           margin: 10px 0;
           .desc{
               font-size: 12px;
           }
       }
       .share-button{
           margin-top: 20px;
           .share-btn{
               border: none;
               width:100%;
               background: #f47500;
               padding: 10px 0;
               border-radius: 5px;
               .button-inner-content{
                   gap: 10px;
               }
           }
       }
       .reload-link{
           margin-top: 10px;
           gap:10px;
       }
      }
  }
    }
   
}
</style>