<template>
  <div class="container flex flex-column ">
      <div>
      <div class="top-title flex justify-center  white">
      ユーザー情報登録
      </div>
    <div class="profile">
        <div class="profile-box">
        <div class="content">
            <div class="top-content flex">
                (*) は入力必須項目です
            </div>
            <div class="image-and-name-container">
            <div class="image-avatar flex justify-center">
                <img src="@/assets/images/avatar.png">
            </div>
            <div class="name flex justify-center">画像をアップロード</div>
            </div>
            <div class="more-information">
                <form @submit.prevent = "submit">
                    <div class="form-control">
                        <label for="nick-name">NickName *</label>
                        <input type="text" id="nick-name" placeholder="Moto" v-model="nickname" required >
                    </div>
                     <div class="form-control">
                        <label for="self-introduction">Self Introduction</label>
                        <input type="text" id="self-introduction" placeholder="If any, text comes here. " v-model="selfIntroduction" >
                    </div>
                     <div class="form-control">
                        <label for="location">Location *</label>
                        <select id="location" v-model="location" required>
                            <option value="Prefecture and city name">Prefecture and city name</option>
                            <option>Prefecture and city name</option>
                        </select>
                    </div>
                    <div class="form-control">
                        <label for="dath-birth">Date of Birth</label>
                        <input type="date" id="date-birth" placeholder="yyyy / mm / dd" v-model="dateOfBirth" >
                    </div>
                    <div class="submit-btn flex justify-center">
                       <button :class="{'bg-orange': submit}" type="submit" class="btn white">登録する</button>
                    </div> 
                </form>
            </div>
        </div>
        
        </div>
    </div>
    </div>
    <div class="footer">
     <Footer />
     </div>
  </div>
</template>

<script>
import Footer from '../components/Footer.vue'
export default {
    components:{
      Footer
    },
data(){
    return{
     nickname:'',
     selfIntroduction: '',
     location: '',
     dateOfBirth: null,
     nicknameLocation: false,
     locationSelected:false,
    }
},
computed:{
submit(){
    return this.nicknameLocation && this.locationSelected
}
},
watch:{
    nickname(){
        this.nickname !='' ? this.nicknameLocation = true : this.nicknameLocation = false
    },
    location(){
        this.location != '' ? this.locationSelected = true : this.locationSelected = false
    }
},
methods:{
    
}
}
</script>

<style lang="scss" scoped>
.container{
    max-width: 375px;
    margin: auto;
  
    justify-content: space-between;
    .top-title{
        background: #F47500;
        height: 36px;
        align-items: center
    }
    .profile{
      
        padding: 10px;
        background: #e5e5e5;
        .profile-box{
            padding: 15px;
            padding-bottom: 80px;
                  background: #FFFFFF;
              border: 1px solid #EAEAEA;
             box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
             border-radius: 5px;
             .top-content{
                 justify-content: flex-end;
                 font-size: 12px;
                 color: #333333;
                 line-height: 14px;
             }
             .image-and-name-container{
                 margin-top: 25px;
                 .name{
                     margin-top: 5px;
                     font-size: 12px;
                     color: #929292;
                 }
             }
             form{
                 .btn{
                    background: #CFCFCF;
                    border-radius: 5px;
                    border: none;
                    width: 80%;
                    padding: 10px 0;
                    margin-top: 40px;
                    cursor: pointer;
                 }
                 .form-control{
                     label{
                         color: #F47500;
                         font-size: 14px;
                         line-height: 16px;
                         display: flex;
                         flex-direction: column;
                         margin-top: 15px;
                     }
                     input , select{
                         border: none;
                         border-bottom: 1px solid #B1B1B1;
                         height: 26px;
                         color: #333333;
                         font-size: 14px;
                         padding: 20px 0;
                         width: 100%;
                         &:focus{
                             outline: none;
                         }
                     }
                     
                 }
             }
             
        }
    }
}

</style>