<template>
  <div class="overlay">
      <div class="box flex ">
          <div class="close-btn flex" @click="setLogin">
                  <img src="@/assets/images/close.png">
              </div>
          <div class="left flex flex-column justify-center">
              <div class="flex justify-center logo">
          <img src="../assets/images/logo1.png">
              </div>
          <div class="description text-center white">
              アカウントへログイン
          </div>
          </div>
          <div class="right">
              
           <div class="title text-center">Login to Toast
           </div>
           <div class="inputs flex flex-column">
               <div class="email">
               <input type="text" placeholder="メールアドレス *">
               <div>
               <img src="@/assets/images/mail.png">
               </div>
               </div>
               <div class="password">
               <input type="text" placeholder="パスワード *">
               <div>
               <img src="@/assets/images/lock.png" >
               </div>
               </div>
                
                 <div class="login">
                     <button class="btn login-btn white">Login</button>
                 </div>
           </div>
         <div class="or-login-with text-center">
             Or login with
         </div>
          <div class="logins flex flex-column">
            <div class="google btn flex">
                <img src="@/assets/images/google.png">
            <div class="text">Login with Google</div>
            </div>
              <div class="facebook btn flex">
                  <img src="@/assets/images/fb.png">
                  <div class="text">Login with Facebook</div></div>
              <div class="line btn flex">
                  <img src="@/assets/images/line.png">
                  <div class="text">Login with Line</div></div>
          </div>
      </div>
  </div>
</div>
</template>

<script>
export default {
methods:{
    setLogin(){
      this.$store.commit('setLoginModal')
      window.onscroll = function(){}
    }
}
}
</script>
<style lang="scss" scoped>
.overlay{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    background: rgba(0,0,0,.5);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .box{
        @media(max-width:520px){
            flex-direction: column;
            background: #fff;
            border-radius: 20px; ;
        }
        width:100%;
        max-width: 980px;
        height: 70vh;
        border-radius: 50px;
        position: relative;
        .close-btn{
              position: absolute;
              right: 40px;
              top: 20px;
             cursor: pointer;
             @media(max-width: 375px){
                 right: 30px;
             }
           }
       .left{
            @media(max-width:520px){
            background: unset;
            border-radius: 0;
            margin-right: 0;
            padding: 0;
        }
           border-radius: 20px;
           margin-right: -20px;
           flex-basis: 40%;
           background: linear-gradient(188.59deg, #F47500 27.77%, #FF9900 77.94%);
           padding: 20px;
           .logo img{
               width: 50%;
                @media(max-width:520px){
                 margin-top: 50px;
        }
           }
           .description{
                @media(max-width:520px){
                    color: #999999;;
        }
               font-size: 16px;
               margin-top: 20px;
           }
       }
       .right{
            @media(max-width:520px){
            background: unset;
            border-radius: 0;
        }
           border-radius: 20px;
           padding: 20px;
           background: #fff;
           flex-basis: 60%;
           
           .title{
               @media(max-width:520px){
              display: none;
              margin-top: 0;
               }
               margin-top: 20px;
               font-size: 30px;
           }
           .inputs{
                align-items: center;
                gap: 12px;
                margin: auto;
                margin-top: 20px;
                width: 60%;
                 @media(max-width:520px){
                    width: 100%;
        }
               input{ 
                   width: 100%;
                    @media(max-width:520px){
                     width: 100%;
        }
              height: 36px;
              background: #FFFFFF;
              border: 1px solid #ADADAD;
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
              border-radius: 5px;
              padding-left: 40px;
              &:focus{
                  outline: none;
              }
               }
               .email , .password{
                   width: 100%;
                    @media(max-width:520px){
                    width: 100%;
        }
                   position: relative;
                   div{
                       height: 100%;
                       left: 12px;
                       position: absolute;
                       display: flex;
                       align-items: center;
                       top: 0;
                   }
               }
              
           }
            .forgot-password{
               font-size: 12px;
               color: #adadad;
               justify-content: flex-end;
               cursor: pointer;
           }
           .or-login-with{
               margin: 10px 0;
               font-size: 12px;
               color: #999999;
           }
           .login{
               width: 100%;
               .btn{
                   background: #F47500;
                   border-radius: 50px;
                   border: none;
               }
               .login-btn{
                   width: 100%;
                   padding: 10px 0;
               }
           }
           .logins{
               align-items: center;
               .google , .facebook , .line{
                   align-items: center;
               }
        .btn{
            margin-top: 10px;
            &:first-child{
                margin-top: 0 !important;
            }
            cursor: pointer;
            gap:10px;
            background: #FFFFFF;
            border: 1px solid #ADADAD;
            box-sizing: border-box;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 50px;
            padding: 10px;
            width: 60%;
             @media(max-width:520px){
            width: 100%;
        }
           }
           }
           
       }
    }
}
</style>