<template>
<div class="container">
  <div class="modal">
      <div class="list-names-modal">
        <div class="arrow-and-title flex justify-center">
       <div class="title flex ">
           <img src="@/assets/images/marker.png" class="contained"/>
           <div>リストを選ぶ</div>
       </div>
       <div class="arrow" @click="$emit('closelist')">
           <img src="@/assets/images/dropdown.png" />
       </div>
       </div>
           <div class="lists flex flex-column">
           <div class="list flex justify-center" v-for="list in 10" :key="list">
               <div class="listname">
                   list name here
               </div>
           </div>
           </div>
      </div>
  </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.container{
    max-width: 375px;
    margin: auto;
.modal{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($color: #000000, $alpha: 0.5);
    .list-names-modal{
    width: 300px;
     background: #fff;
     border-radius: 20px;
     padding: 20px 10px;
     border-radius: 20px;
     .arrow-and-title{
         gap: 20px;
     .title{
         gap: 10px;
     }
     .arrow{
         cursor: pointer;
     }
     }
     .lists{
         margin-top: 20px;
         .list{
             padding: 10px;
             border-top: 1px solid #c3c3c3;
             cursor: pointer;
         }
         &:last-child{
             border-bottom: 1px solid #c3c3c3;
         }
     }
    }
}
}
</style>