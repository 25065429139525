import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import MyProfile from '../views/MyProfile.vue'
import TopFeed from '../views/TopFeed.vue'
import List from '../views/List.vue'
import IndividualList from '../views/IndividualList'
import IndividualMap from '../views/IndividualMap'
import ReviewDetail from '../views/ReviewDetail'
import Email from '../views/Email'
import Password from '../views/Password'
import FriendComments from '../views/FriendComments'
import NewReview from '../views/NewReview'
import EditReview from '../views/EditReview'
import FriendRequest from '../views/FriendRequest'
import FriendRequestDetail from '../views/FriendRequestDetail'
import EditList from '../views/EditList'
import ShareList from '../views/ShareList'
import ShareLink from '../views/ShareLink'
import ShareReviews from '../views/ShareReviews'
import FriendList from '../views/FriendList'
import SearchFriend from '../views/SearchFriend'
import AddBySNSorEmail from '../views/AddBySNSorEmail'
import AddFriendbyQrCode from '../views/AddFriendbyQrCode'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/myprofile',
    name:'Profile',
    component: MyProfile
  },
  {
    path: '/myprofile/email',
    name: 'Email',
    component: Email
  },
  {
    path: '/myprofile/password',
    name: 'Password',
    component: Password
  },
  {
    path: '/topfeed',
    name: 'TopFeed',
    component: TopFeed
  },
  {
    path:'/list',
    name: 'List',
    component: List
  },{
    path: '/list/:id',
    name:'IndividualList',
    component: IndividualList
  },
  {
    path: '/map',
    name: 'Map',
    component: IndividualMap
  },{
    path: '/review',
    name: 'Review',
    component: ReviewDetail
  },
  {
    path: '/friendcomments',
    name: 'FriendComments',
    component: FriendComments
  },
  {
  path: '/newreview',
  name:'NewReview',
  component: NewReview
  },
  {
    path: '/editreview',
    name: 'EditReview',
    component: EditReview
  },
  {
    path: '/frienddetail',
    name: 'FriendDetail',
    component: FriendRequestDetail,
  },
  {
    path: '/friendrequest',
    name: 'FriendRequest',
    component: FriendRequest
  },
  {
    path: '/editlist',
    name: 'EditList',
    component: EditList
  },
  {
    path: '/sharelist',
    name: 'ShareList',
    component: ShareList
  },
  {
    path: '/sharelink',
    name: 'ShareLink',
    component: ShareLink
  },
  {
    path: '/sharereview',
    name: 'ShareReview',
    component: ShareReviews
  },
  {
    path: '/friendlist',
    name: 'FriendList',
    component: FriendList
  },
  {
    path: '/searchfriend',
    name: 'SearchFriend',
    component: SearchFriend
  },
  {
    path: '/addbysnsoremail',
    name: 'AddBySNSorEmail',
    component: AddBySNSorEmail
  },
  {
  path: '/addfriendbyqrcode',
  name: 'AddFriendByQrCode' ,
  component: AddFriendbyQrCode
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
